import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Icon, Modal, TextArea } from "semantic-ui-react";

import { DATE_FORMATS, UploadFilesZone, UploadedFilesPreview } from "components";
import "components/CarNoteModal/CarNoteModal.scss";
import { AddUpdateNoteHeader } from "components/Notes";
import { useDealersLocations, useUser } from "hooks";
import { CAR_NOTE_TYPE, Car, CarNote, CarNoteAttachment } from "models";
import { useAppointmentHistory, useCarNotes, useUploadCarNoteAttachment } from "modules/CarDetails/hooks";
import { ITranslation } from "util/interfaces";

type CarNoteModalProps = {
  selectedNote: CarNote | null;
  noteType: CAR_NOTE_TYPE | null;
  resetNoteType: () => void;
  onSetSelectedCarNote: (note: CarNote | null) => void;
  onUnselectNote: () => void;
  car: Car;
};

export const CarNoteModal = ({ selectedNote, noteType, resetNoteType, onSetSelectedCarNote, onUnselectNote, car }: CarNoteModalProps) => {
  const t = useTranslation().t as ITranslation;
  const { data: appointmentHistory } = useAppointmentHistory(String(car.id));
  const sortedAppointments = [...(appointmentHistory ?? [])].sort((app1, app2) => app2.date.localeCompare(app1.date));

  const { addCarNote, updateCarNote, deleteCarNote } = useCarNotes(car.id, sortedAppointments.length > 0 ? sortedAppointments[0].id : 0);
  const { handleUploadCarNoteAttachment } = useUploadCarNoteAttachment();

  const { selectedLocation } = useDealersLocations();
  const user = useUser();

  const [note, setNote] = useState("");
  const [visibleToMechanic, setVisibleToMechanic] = useState(!!selectedNote?.visible_on_wo);
  const [visibleOnWO, setVisibleOnWO] = useState<boolean>(!!selectedNote?.visible_on_wo);
  const [attachments, setAttachments] = useState<Partial<CarNoteAttachment>[] | null>(null);
  const [hasValidationError, setHasValidationError] = useState(false);

  useEffect(() => {
    const note = selectedNote?.note || "";
    const visibleToMechanic = !!selectedNote?.visible_to_mechanic;
    const visibleOnWO = !!selectedNote?.visible_on_wo;
    const attachments = selectedNote?.attachments || null;
    setNote(note);
    setVisibleToMechanic(visibleToMechanic);
    setVisibleOnWO(visibleOnWO);
    setAttachments(attachments);
  }, [selectedNote]);

  const handleResetCarNotesState = () => {
    setNote("");
    setVisibleToMechanic(false);
    setVisibleOnWO(false);
    setAttachments(null);
    setHasValidationError(false);
  };

  const handleModalClose = () => {
    resetNoteType();
    setHasValidationError(false);
    handleResetCarNotesState();
  };

  const handleTextAreaChange = (data: string) => {
    setNote(data);
  };

  const handleVisibleToMechanic = (checked: boolean | undefined) => {
    setVisibleToMechanic(!!checked);
  };

  const handleVisibleOnWO = (checked: boolean | undefined) => {
    setVisibleOnWO(!!checked);
  };

  const handleUploadAttachment = async (file: File) => {
    if (!file) return;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });
    const formData = new FormData();

    const date = moment(car.created_on).format(DATE_FORMATS.yearMonthDateSlash);
    const path = `${selectedLocation?.id}_${selectedLocation?.name}/${date}/${car.id}_${car.reg_nr}`;

    formData.append("file", uploadFile);
    formData.append("path", path);

    const attachmentUrl = await handleUploadCarNoteAttachment({ formData });
    if (attachmentUrl) {
      const newFile = {
        url: attachmentUrl,
        type: file.type || extension,
        name: file.name,
        username: `${user?.first_name || ""} ${user?.last_name || ""}`
      };
      setAttachments(attach => (attach ? [...attach, newFile] : [newFile]));
    }
  };

  const handleDeleteAttachment = (url: string) => {
    if (attachments?.length) setAttachments(attach => (attach ? attach.filter(attachment => attachment.url !== url) : null));
  };

  const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    [...(evt.target.files || [])].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  const handleFileDragOver = (evt: React.DragEvent<HTMLInputElement>) => {
    evt.preventDefault();
  };

  const handleFileDrop = (evt: React.DragEvent<HTMLInputElement>) => {
    evt.preventDefault();

    [...evt.dataTransfer.files].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  const validateRequest = (type: CAR_NOTE_TYPE) => {
    if (type === CAR_NOTE_TYPE.Default) {
      if (!note) {
        setHasValidationError(true);
        return false;
      }
      return true;
    }
    setHasValidationError(false);
    return true;
  };

  const handleCreateNote = (type: number | null) => {
    if (!type) return;
    const isValid = validateRequest(type);
    if (isValid) {
      const payload = {
        car_id: car.id,
        note,
        attachments: attachments?.length ? attachments : null,
        visible_to_mechanic: visibleToMechanic,
        visible_on_wo: visibleOnWO,
        car_note_type_id: CAR_NOTE_TYPE.Default,
        created_by: user,
        created_on: moment().format(DATE_FORMATS.yearMonthDateTime),
        created_by_id: user?.id
      };

      addCarNote.mutate({ payload });
      resetNoteType();
      handleResetCarNotesState();
    }
  };

  const handleUpdateNote = (type: number | null) => {
    if (!type || !selectedNote) return;
    const isValid = validateRequest(type);

    if (isValid) {
      const payload = {
        car_id: car.id,
        note,
        attachments: attachments?.length ? attachments : null,
        visible_to_mechanic: visibleToMechanic,
        visible_on_wo: visibleOnWO,
        car_note_type_id: selectedNote.car_note_type_id,
        id: selectedNote.id,
        updated_by: user,
        updated_on: moment().format(DATE_FORMATS.yearMonthDateTime),
        updated_by_id: user?.id
      };
      updateCarNote.mutate({ payload });
      resetNoteType();
      onSetSelectedCarNote(null);
      handleResetCarNotesState();
    }
  };

  const handleDeleteNote = (noteID: number) => {
    deleteCarNote.mutate({ noteID });
    onSetSelectedCarNote(null);
  };

  const handleSubmitNote = () => {
    if (selectedNote && selectedNote.id > 0) return handleUpdateNote(selectedNote.car_note_type_id);
    return handleCreateNote(CAR_NOTE_TYPE.Default);
  };

  return (
    <Modal open className="AddUpdateCarNote">
      <Modal.Header className="add-update-car-note-modal-header">
        <AddUpdateNoteHeader type={noteType} isCarNote={true} isEditing={!!selectedNote} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field error={hasValidationError && !note} required={true}>
            <label>{t("v8_note").message || "Note"}</label>
            <TextArea
              className="add-update-car-note-textare-field"
              rows={5}
              value={note}
              name="note"
              onChange={evt => handleTextAreaChange(evt.target.value)}
              placeholder={`${t("v8_type_note_here").message || "Type note here"}...`}
            />
          </Form.Field>
          <div className="add-update-car-note-checkbox-fields">
            <div className="add-update-car-note-checkbox-field">
              <Checkbox
                toggle
                checked={visibleToMechanic}
                name="visibleToMechanic"
                label={t("v8_note_visible_to_mechanic").message || "Note visible to mechanic"}
                onChange={(_, { checked }) => handleVisibleToMechanic(checked)}
              />
            </div>
            <div className="add-update-car-note-checkbox-field">
              <Checkbox
                toggle
                checked={visibleOnWO}
                name="visibleOnWO"
                label={t("v8_visible_on_wo").message || "Visible on WO"}
                onChange={(_, { checked }) => handleVisibleOnWO(checked)}
              />
            </div>
          </div>
          <Form.Field>
            <UploadFilesZone hasError={false} display={true} onFileInputChange={handleFileChange} onFileDragOver={handleFileDragOver} onFileDrop={handleFileDrop} />
          </Form.Field>
          <UploadedFilesPreview attachments={attachments} onDeleteAttachment={handleDeleteAttachment} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <div className={`${selectedNote ? "update-car-note-modal-actions" : "add-car-note-modal-actions"}`}>
          {!!selectedNote && (
            <div>
              <Button
                onClick={() => handleDeleteNote(selectedNote?.id)}
                color="red"
                loading={deleteCarNote.isPending}
                disabled={addCarNote.isPending || updateCarNote.isPending}
              >
                {t("v8_delete").message || "Delete"}
              </Button>
            </div>
          )}

          <div>
            <Button color="light" onClick={selectedNote && selectedNote.id > 0 ? onUnselectNote : handleModalClose}>
              {t("v8_cancel").message || "Cancel"}
              <Icon className="xmark" />
            </Button>
            <Button type="submit" color="green" onClick={handleSubmitNote} disabled={deleteCarNote.isPending} loading={addCarNote.isPending || updateCarNote.isPending}>
              {t("v8_save").message || "Save"}
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
