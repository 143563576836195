import ENV from "config/Env";

export enum ORIGIN {
  Backend = "backend",
  Local = "local"
}

export enum UPDATE_TYPE {
  Static = "static",
  WebSocket = "websocket"
}

interface IQueryKey {
  component: string;
}

export interface IBackendQueryKey extends IQueryKey {
  updateType: UPDATE_TYPE;
  baseUrl: string;
  endpoint: string;
  params?: Record<string, unknown>;
}

interface ILocalQueryKey extends IQueryKey {
  data: Record<string, unknown> | string;
}

export const backendQueryKey = (key: IBackendQueryKey) => {
  return { origin: ORIGIN.Backend, ...key };
};

export const localQueryKey = (key: ILocalQueryKey) => {
  return { origin: ORIGIN.Local, ...key };
};

// everything after this line is to be refactored to use the above two methods

export interface BackendQueryKey {
  origin: ORIGIN.Backend;
  baseUrl: string;
  endpoint: string;
  params?: Record<string, unknown>;
}

export interface LocalQueryKey {
  origin: ORIGIN.Local;
  component: string;
  data?: Record<string, unknown> | string;
}

export type QueryKeyType = BackendQueryKey | LocalQueryKey;

interface QueryKeys {
  common: Record<string, QueryKeyType>;
  dealers: Record<string, QueryKeyType>;
  appointments: Record<string, QueryKeyType>;
  dealer: Record<string, QueryKeyType>;
  location: Record<string, QueryKeyType>;
  car: Record<string, QueryKeyType>;
}

export enum QUERY_KEY_COMPONENTS {
  Websocket = "websocket",
  UserData = "userData",
  AuthInfo = "authInfo",
  PinDropdownOptions = "pinDropdownOptions"
}

export const queryKeys: QueryKeys = {
  common: {
    isTokenExpired: { origin: ORIGIN.Local, component: "App", data: "isTokenExpired" }
  },
  dealers: {
    list: { origin: ORIGIN.Backend, baseUrl: ENV.dealerBaseUrl, endpoint: "dealers/list?embed=dealer_location" },
    commSettings: { origin: ORIGIN.Backend, baseUrl: ENV.dealerBaseUrl, endpoint: "communication/settings/read" }
  },
  dealer: {
    id: { origin: ORIGIN.Local, component: "DealersLocationsDropdown", data: "selectedDealerId" },
    details: { origin: ORIGIN.Local, component: "DealersLocationsDropdown", data: "selectedDealer" }
  },
  location: {
    details: { origin: ORIGIN.Backend, baseUrl: ENV.apiBase, endpoint: "locations/details" },
    id: { origin: ORIGIN.Local, component: "DealersLocationsDropdown", data: "selectedLocationId" }
  },
  appointments: {
    specialIndicators: { origin: ORIGIN.Local, component: "Appointments", data: "specialIndicators" },
    carMakes: { origin: ORIGIN.Local, component: "Appointments", data: "carMakes" },
    carMakeSearch: { origin: ORIGIN.Local, component: "Appointments", data: "carMakeSearch" },
    tyreBrands: { origin: ORIGIN.Backend, baseUrl: ENV.apiBase, endpoint: "/tyres/list_brands" },
    deliveryTypes: { origin: ORIGIN.Backend, baseUrl: ENV.tyreteamBaseURL, endpoint: "/catalog/list_delivery_types" },
    tyreSearch: { origin: ORIGIN.Backend, baseUrl: ENV.tyreteamBaseURL, endpoint: "/catalog/search" },
    selectedIdentifiers: { origin: ORIGIN.Local, component: "Appointments", data: "selectedIdentifiers" },
    connectedInterventionId: { origin: ORIGIN.Local, component: "InterventionChecklist", data: "connectedInterventionId" }
  },
  car: {
    appintmentHistory: { origin: ORIGIN.Backend, baseUrl: ENV.carBaseUrl, endpoint: "/cars/checks/list" },
    pinItems: { origin: ORIGIN.Backend, baseUrl: ENV.appointmentBaseURL, endpoint: "/pins/list_by_car_id" },
    snoozeItems: { origin: ORIGIN.Backend, baseUrl: ENV.appointmentBaseURL, endpoint: "/snoozes/list_by_car_id" }
  }
};

export const staticLocalKey = (component: string) => ["static", { origin: ORIGIN.Local, component }];

export const realtimeLocalKey = (component: string) => ["realtime", { origin: ORIGIN.Local, component }];
