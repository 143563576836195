import { AddEditDealerDocumentationModal, DealerDocumentationReadingModal, DealersDocumentationTable } from "modules/DealerDocumentations/components";
import { DealerDocumentationsToolbar } from "modules/DealerDocumentations/components";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import "modules/DealerDocumentations/DealerDocumentations.scss";
import { useDealerCategories, useDealerDocumentations } from "modules/DealerDocumentations/hooks";
import { useGetDealersDocumentations } from "modules/DealerDocumentations/service";

const DealerDocumentationsPage = () => {
  const { categoryOptions } = useDealerCategories();
  const { showAddEditDealerDocumentationModal } = useDealerDocumentationContext();
  useGetDealersDocumentations();
  useDealerDocumentations();

  return (
    <>
      <div className="dealer-documentations-container">
        <DealerDocumentationsToolbar categoryOptions={categoryOptions} />
        <DealersDocumentationTable />
      </div>
      <DealerDocumentationReadingModal />
      {showAddEditDealerDocumentationModal && <AddEditDealerDocumentationModal categoryOptions={categoryOptions} documentation={showAddEditDealerDocumentationModal} />}
    </>
  );
};

export default DealerDocumentationsPage;
