import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "CarDetails";

export const CarDetailsKeys = {
  details: (id: number) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.carBaseUrl,
      endpoint: "/cars/details",
      params: { id }
    })
  ]
};
