import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "components/Pagination/Pagination.scss";
import { ITranslation } from "util/interfaces";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  totalItems: number;
  pageSize: number;
};

export const Pagination = ({ currentPage, totalPages, onPageChange, totalItems, pageSize }: PaginationProps) => {
  const t = useTranslation().t as ITranslation;

  const getPageNumbers = () => {
    if (totalPages <= 3) return Array.from({ length: totalPages }, (_, i) => i + 1);

    const start = Math.min(Math.max(currentPage - 1, 1), totalPages - 2);
    return [start, start + 1, start + 2];
  };

  const pages = useMemo(() => getPageNumbers(), [currentPage, totalPages]);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(startItem + pageSize - 1, totalItems);

  return (
    <div className="pagination-container">
      <div className="pagination-controls">
        <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className="nav-button">
          ‹
        </button>

        {pages.map(page => (
          <button key={page} onClick={() => onPageChange(page)} className={currentPage === page ? "active" : ""}>
            {page}
          </button>
        ))}

        <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className="nav-button">
          ›
        </button>
      </div>

      <div className="pagination-info">
        {t("v8_results").message || "Results"}: {startItem}-{endItem}/{totalItems}
      </div>
    </div>
  );
};

export default Pagination;
