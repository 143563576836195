import ENV from "config/Env";
import { ExactFailuresFilters } from "modules/ExactFailures/util/types";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

export const ExactFailuresQueryKeys = {
  failures: (filters: ExactFailuresFilters) => {
    const isValidStatusCode = filters.status_code && filters.status_code >= 100 && filters.status_code <= 599;
    const dateRange = filters.date_from && filters.date_to && { date_from: filters.date_from, date_to: filters.date_to };
    const params = { ...filters, status_code: isValidStatusCode ? filters.status_code : null, ...dateRange };

    return [
      backendQueryKey({
        updateType: UPDATE_TYPE.Static,
        component: "ExactFailures",
        baseUrl: ENV.exactBaseUrl,
        endpoint: "/failures/list",
        params
      })
    ];
  }
};
