import { Icon } from "semantic-ui-react";

import "modules/DealerDocumentations/components/DealerDocumentationReadingModal/components/ReadingModalImage/ReadingModalImage.scss";
import { getNameFromURL } from "util/common";

interface PropTypes {
  url: string;
}

export const ReadingModalImage = ({ url }: PropTypes) => {
  const fileName = getNameFromURL(url);

  return (
    <div>
      <Icon className="image" />
      <a href={url} target="_blank" rel="noreferrer" className="url-to-preview">
        {fileName}
      </a>
    </div>
  );
};
