import { OnChangeFn, PaginationState, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { ReactNode } from "react";

import { Pagination } from "components";
import "components/ReactTable/ReactTable.scss";

interface ReactTableProps {
  data: any;
  columns: any[];
  renderEmptyRow?: boolean;
  emptyRowMessage?: string;
  customHeader?: ReactNode;
  state?: { columnVisibility: Record<string, boolean> };
  onRowClick?: (d: any) => void;
  pageCount?: number;
  totalItems?: number;
  pageSize?: number;
  pagination?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
}
export const ReactTable = ({
  data,
  columns,
  renderEmptyRow,
  customHeader,
  emptyRowMessage = "",
  state,
  onRowClick,
  pageCount,
  totalItems,
  pagination,
  pageSize,
  onPaginationChange
}: ReactTableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...(pagination
      ? {
          getPaginationRowModel: getPaginationRowModel(),
          state: {
            ...(state || {}),
            pagination
          },
          onPaginationChange,
          manualPagination: true,
          pageCount
        }
      : {
          state: state || {}
        })
  });

  return (
    <div className="ReactTable-wrapper">
      <div className="ReactTable-body">
        <table className="ReactTable">
          <thead>
            {customHeader ||
              table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th style={{ width: header.column.columnDef.size }} key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              if (onRowClick) {
                return (
                  <tr key={row.id} className="hover" onClick={() => onRowClick(row.original)}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                );
              }
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              );
            })}
            {renderEmptyRow && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  {emptyRowMessage}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="ReactTablePagination">
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            onPageChange={page => {
              table.setPageIndex(page - 1);
            }}
            pageSize={pageSize || 0}
            totalItems={totalItems || 0}
          />
        </div>
      )}
    </div>
  );
};
