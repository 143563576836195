import { CircleIcon, ColorKeys } from "components";
import CartIndicators from "modules/Appointments/components/SpecialIndicators/CartIndicators";
import "modules/Appointments/components/SpecialIndicators/SpecialIndicators.scss";
import { useGetSpecialIndicatorsInitialState } from "modules/Appointments/hooks/useGetSpecialIndicatorsInitialState";

export type IndicatorFilters = {
  icon: ColorKeys;
  active: boolean;
  filter: string;
  borderColor?: string;
  text?: string;
};

export const SpecialIndicators = () => {
  const { indicatorFilters, handleIndicatorFilters } = useGetSpecialIndicatorsInitialState();
  return (
    <div className="SpecialIndicators">
      {indicatorFilters.map((item, index) => {
        return (
          <CircleIcon
            key={item.filter}
            icon={item.icon}
            disabled={!item.active}
            borderColor={item.borderColor}
            color={item.borderColor}
            onClick={() => handleIndicatorFilters(index)}
          />
        );
      })}
      <CartIndicators />
    </div>
  );
};
