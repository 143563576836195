import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "Dayplanner";

export const DayplannerKeys = {
  appointments: (params: Record<string, unknown>) => [
    backendQueryKey({ updateType: UPDATE_TYPE.WebSocket, component, baseUrl: ENV.appointmentBaseURL, endpoint: "/dayplanner/appointments/list", params })
  ],
  mechanics: (params: Record<string, unknown>) => [
    backendQueryKey({ updateType: UPDATE_TYPE.WebSocket, component, baseUrl: ENV.appointmentBaseURL, endpoint: "/dayplanner/mechanics/list", params })
  ]
};
