import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { DOCUMENTATION_CATEGORIES } from "models";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { ITranslation } from "util/interfaces";

const defaultDocumentationData = {
  id: 0,
  title: "",
  content: "",
  publish_date: moment(new Date()).toDate(),
  url: undefined,
  is_pinned: false,
  visible_to_mechanic: false,
  visible_to_all: false,
  category_id: DOCUMENTATION_CATEGORIES.Manual,
  dealer_ids: [],
  dealer_location_ids: []
};

export const AddDealerDocumentationsButton = () => {
  const t = useTranslation().t as ITranslation;
  const { handleSetShowAddEditDealerDocumentationModal } = useDealerDocumentationContext();
  const handleOnClick = () => handleSetShowAddEditDealerDocumentationModal(defaultDocumentationData);

  return (
    <Button icon labelPosition="left" onClick={handleOnClick}>
      <Icon className="plus" />
      {t("v8_add").message || "Add"}
    </Button>
  );
};
