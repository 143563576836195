import { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";

import "components/CopyToClipboard/CopyToClipboard.scss";
import { getErrorMessage } from "util/common";

type Props = {
  text: string;
  onSuccess?: () => void;
  onError?: (err?: string) => void;
  showPopup?: boolean;
};

export const CopyToClipboard = ({ text, onSuccess, onError, showPopup = false }: Props) => {
  const [hover, setHover] = useState(false);

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      if (onSuccess) onSuccess();
    } catch (err) {
      console.error("Something went wrong, cannot copy the value", err);
      if (onError) onError(getErrorMessage(err) || "Couldn't copy the text");
    }
  };

  return (
    <Popup
      trigger={
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="CopyToClipboard__container">
          <span onClick={onCopy} className={`CopyToClipboard__icon ${hover ? "visible" : ""}`}>
            <Icon name="copy" />
          </span>
          <span className="CopyToClipboard__text">{text}</span>
        </div>
      }
      flowing
      content={<div className="CopyToClipboard__content">{text}</div>}
      disabled={!showPopup}
      style={{
        padding: "20px",
        maxWidth: "1400px",
        minWidth: "50px"
      }}
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: "offsetParent",
              padding: 10
            }
          },
          {
            name: "flip",
            options: {
              flipVariations: true,
              padding: 10
            }
          },
          {
            name: "size",
            options: {
              padding: 10
            }
          }
        ]
      }}
    />
  );
};
