import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { DetailsCards, TabData, useCan } from "components";
import { CarNoteModal } from "components/CarNoteModal";
import { DMSNotes, NoteRow } from "components/Notes";
import { CAR_NOTE_TYPE, Car, CarNote } from "models";
import "modules/CarDetails/components/CarNotes/CarNotes.scss";
import { ITranslation } from "util/interfaces";

type CarNotesProps = {
  car: Car;
  isExpanded: boolean;
  tabData: TabData;
};

export const CarNotes = ({ car, tabData, isExpanded }: CarNotesProps) => {
  const t = useTranslation().t as ITranslation;
  const [showCardDetails, setShowCardDetails] = useState(isExpanded);
  const [selectedCarNote, setSelectedCarNote] = useState<CarNote | null>(null);
  const [noteType, setNoteType] = useState<CAR_NOTE_TYPE | null>(null);
  const canCreateNotes = useCan("create", "car-notes");

  useEffect(() => {
    setShowCardDetails(isExpanded);
  }, [isExpanded]);

  const toggleCardDetails = () => {
    setShowCardDetails(prevState => !prevState);
  };

  useEffect(() => {
    if (!noteType) setNoteType(null);
    else if (noteType === CAR_NOTE_TYPE.Default) {
      const note = car.notes?.find(note => note.car_note_type_id === noteType);
      if (note) setSelectedCarNote(note);
    }
  }, [noteType]);

  const handleSelectCarNote = (note: CarNote | null) => {
    setNoteType(note?.car_note_type_id || null);
    setSelectedCarNote(note);
  };

  const handleUnselectNote = () => {
    setSelectedCarNote(null);
    setNoteType(null);
  };

  const dmsCarNotes = car?.notes?.filter((note: CarNote) => note.car_note_type_id === CAR_NOTE_TYPE.DMS) || [];
  const defaultCarNotes = car?.notes?.filter((note: CarNote) => note.car_note_type_id === CAR_NOTE_TYPE.Default) || [];

  return (
    <DetailsCards
      id={tabData.id}
      containerStyle="mt-20"
      title={`${t("notes").message || "Notes"} ${tabData.data.length > 0 && car ? `(${tabData.data.length})` : ""}`}
      icon="note sticky"
      showExpandArrow={false}
      rightContent={
        canCreateNotes &&
        defaultCarNotes?.length < 1 &&
        car && (
          <>
            <Icon className={`chevron ${showCardDetails ? "down" : "right"} pointer`} onClick={toggleCardDetails} />
            <Button className="-appointment-status button-note" size="small" onClick={() => setNoteType(CAR_NOTE_TYPE.Default)}>
              <Icon name="plus" color="green" size="big" />
            </Button>
          </>
        )
      }
    >
      <div className="CarNotes">
        <div className="car-notes-content">
          {!!defaultCarNotes?.length &&
            showCardDetails &&
            defaultCarNotes.map(note => {
              return <NoteRow carNote={note} isCarNote onSelectNote={() => handleSelectCarNote(note)} key={note.id} />;
            })}

          {dmsCarNotes?.length > 0 && showCardDetails && (
            <div className="dms-remarks-container">
              <DMSNotes dmsCarNotes={dmsCarNotes} />
            </div>
          )}
        </div>

        {(!!noteType || !!selectedCarNote) && (
          <CarNoteModal
            noteType={noteType}
            selectedNote={selectedCarNote}
            onUnselectNote={handleUnselectNote}
            car={car}
            onSetSelectedCarNote={handleSelectCarNote}
            resetNoteType={() => setNoteType(null)}
          />
        )}
      </div>
    </DetailsCards>
  );
};
