import { PropsWithChildren } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { FormProps, Form as SemanticForm } from "semantic-ui-react";

interface FormFacadeProps<T extends FieldValues> extends PropsWithChildren {
  onSubmit: (data: T) => void;
  defaultValues?: T | undefined;
  formProps?: FormProps;
}

export const Form = <T extends FieldValues>({ onSubmit, defaultValues, formProps, children }: FormFacadeProps<T>) => {
  const methods = useForm<T>({
    values: defaultValues,
    mode: "all"
  });

  return (
    <FormProvider {...methods}>
      <SemanticForm loading={methods.formState.isSubmitting} onSubmit={methods.handleSubmit(onSubmit)} {...formProps} disabled={methods.formState.isSubmitting}>
        {children}
      </SemanticForm>
    </FormProvider>
  );
};
