import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "semantic-ui-react";

import { CircleIcon } from "components";
import "modules/Appointments/components/SpecialIndicators/SpecialIndicators.scss";
import { useGetSpecialIndicatorsInitialState } from "modules/Appointments/hooks/useGetSpecialIndicatorsInitialState";
import { ITranslation } from "util/interfaces";

const CartIndicators = () => {
  const t = useTranslation().t as ITranslation;
  const { cartFilters, handleCartFilters, handleSelectAllCarts } = useGetSpecialIndicatorsInitialState();
  if (cartFilters.length === 1) {
    const cart = cartFilters[0];

    return <CircleIcon icon={cart.icon} disabled={!cart.active} borderColor={cart.borderColor} color={cart.borderColor} onClick={() => handleCartFilters(0)} />;
  }

  return (
    <Dropdown
      className="carts-dropdown"
      icon={
        <>
          <Icon className="shopping cart shoppingCartIcon" />

          {cartFilters.some(e => e.active) ? (
            <>
              <Icon className="filter grayFunnelIcon" />
              <Icon className="filter absoluteFunnel" />
            </>
          ) : (
            <Icon className="filter grayFunnelIcon" />
          )}
        </>
      }
    >
      <Dropdown.Menu className="dropdown-menu-container">
        <Dropdown.Item className="cart-all-option" onClick={handleSelectAllCarts}>
          {t("v8_all").message || "All"}
        </Dropdown.Item>
        {cartFilters.map((cart, i) => (
          <Dropdown.Item selected={cart.active} key={i} onClick={() => handleCartFilters(i)} className={cart.active ? "cart-selected-option" : ""}>
            <Icon className={`shopping cart ${cart.borderColor}`} /> {cart.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CartIndicators;
