import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "DealersDocumentations";

export const DealerDocumentationsQueryKeys = {
  list: backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.dealerBaseUrl, endpoint: "/documentations/list" }),
  create: { baseUrl: ENV.dealerBaseUrl, endpoint: "/documentations/create" },
  update: { baseUrl: ENV.dealerBaseUrl, endpoint: "/documentations/update" },
  delete: { baseUrl: ENV.dealerBaseUrl, endpoint: "/documentations/delete" },
  upload: { baseUrl: ENV.dealerBaseUrl, endpoint: "/documentations/upload" }
};
