import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useUser } from "hooks";
import { Appointment, AppointmentNote, AppointmentNoteAttachment } from "models";
import { useAppointment } from "modules/AppointmentDetails/hooks";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

type UseAppointmentNoteRequest = {
  path: string;
  payload: Record<string, string | number | undefined | null | Date | boolean | Partial<AppointmentNoteAttachment> | Partial<AppointmentNoteAttachment>[]>;
};

// TODO: cleanup like other hooks
export const useAppointmentNotes = (appointment_id: number) => {
  const queryClient = useQueryClient();
  const user = useUser();

  const { appointment } = useAppointment(appointment_id);

  const appointmentDetailsViewKey = AppointmentDetailsKeys.view(appointment_id);

  const updateAppointmentDetailsQueryCache = (update: Appointment) => {
    queryClient.setQueryData(appointmentDetailsViewKey, update);
  };

  const addNote = async ({ path, payload }: UseAppointmentNoteRequest): Promise<number> => {
    const url = payload.new_status_identifier ? ENV.apiBase : ENV.appointmentBaseURL;
    const response = await ApiInstance.post(path, payload, url);
    return response?.data?.appointment_note_id;
  };

  // TODO: for every create/append mutation we need to check if it doesn't exists already as the WS might already have added it
  // TODO: mutating the appointment is not ok, need to complete cleaning the models so we can spread them properly
  // TODO: must be doing optimistic updates with onMutate, not post success update
  const addAppointmentNote = useMutation({
    mutationFn: addNote,
    onSuccess: (id: number, variables) => {
      const { note, sa_remarks: saRemark, attachments, attachment, note_attachments: noteAttachments, ...rest } = variables.payload;
      const newNote = { id, note: note || saRemark, attachments: attachments || noteAttachments || (attachment && [attachment]), user, ...rest } as AppointmentNote;
      if (appointment && !appointment.notes?.some(n => n.id === newNote.id)) {
        appointment.notes = [...(appointment.notes ?? []), newNote];
        updateAppointmentDetailsQueryCache(appointment);
      }
    },
    onMutate: newNote => newNote,
    onError: error => toast.error(error.message)
  });

  const updateNote = async ({ path, payload }: UseAppointmentNoteRequest): Promise<number> => {
    const url = payload.new_status_identifier ? ENV.apiBase : ENV.appointmentBaseURL;
    const response = await ApiInstance.post(path, payload, url);
    return response.data;
  };

  const updateAppointmentNote = useMutation({
    mutationFn: updateNote,
    onSuccess: (_, variables) => {
      if (appointment) {
        appointment.notes = appointment.notes?.map((appointmentNote: AppointmentNote) => {
          if (appointmentNote.id === variables.payload.appointment_note_id) {
            const { note, sa_remarks: saRemark, attachments, attachment, note_attachments: noteAttachments, ...rest } = variables.payload;
            const newNote = {
              ...appointmentNote,
              note: note || saRemark,
              attachments: attachments || noteAttachments || (attachment && [attachment]),
              ...rest
            } as AppointmentNote;
            return newNote;
          }
          return appointmentNote;
        });
        updateAppointmentDetailsQueryCache(appointment);
      }
    },
    onMutate: updatedNote => updatedNote,
    onError: error => toast.error(error.message)
  });

  const deleteNote = async ({ noteID }: { noteID: number }) => {
    await ApiInstance.post("/notes/delete", { appointment_note_id: noteID }, ENV.appointmentBaseURL);
  };

  const deleteAppointmentNote = useMutation({
    mutationFn: deleteNote,
    onSuccess: (_, variables) => {
      if (appointment) {
        appointment.notes = appointment.notes?.filter((note: AppointmentNote) => note.id !== variables.noteID);
        updateAppointmentDetailsQueryCache(appointment);
      }
    },
    onError: error => toast.error(error.message)
  });

  return { addAppointmentNote, updateAppointmentNote, deleteAppointmentNote };
};
