import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { toast } from "react-toastify";

import { useUser } from "hooks";
import { DMS, Dealer, DealerLocation, ROLES } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useDealersLocations = () => {
  const user = useUser();
  const queryClient = useQueryClient();
  const dealerListKey = [queryKeys.dealers.list as BackendQueryKey];

  const { data: selectedDealer }: { data: Dealer | undefined } = useQuery({ queryKey: [queryKeys.dealer.details] });
  const { data: locationId }: { data: number | undefined } = useQuery({ queryKey: [queryKeys.location.id] });
  const { data: dealers }: { data: Dealer[] | undefined } = useQuery({ queryKey: dealerListKey });

  const userNotFound = () => {
    toast.error("User not found");
  };

  const fetchLocation = async (id: number): Promise<Dealer[]> => {
    if (!id) throw new Error("No selected location");
    const response = await ApiInstance.post(`/locations/details`, { id });
    return response.data;
  };

  const locationMutation = useMutation({
    mutationFn: fetchLocation,
    onSuccess: (data, selectedLocationId) => {
      queryClient.setQueryData([{ ...queryKeys.location.details, params: { id: selectedLocationId } }], data);
      queryClient.setQueryData([queryKeys.location.id], selectedLocationId);
    },
    onMutate: () => {
      const previousLocationId = queryClient.getQueryData<number>([queryKeys.location.id]);
      return { previousLocationId };
    },
    onError: (error, _selectedLocationId, context) => {
      const previousLocationId = context?.previousLocationId;
      if (previousLocationId) queryClient.setQueryData([queryKeys.location.id], previousLocationId);
      console.error(error);
    }
  });

  const fetchDealers = async (queryKey: BackendQueryKey[]): Promise<Dealer[]> => {
    const [{ endpoint, params, baseUrl }] = queryKey;
    const response = await ApiInstance.post(`/${endpoint}`, params, baseUrl);
    return response.data.dealers;
  };

  const dealerMutation = useMutation({
    mutationFn: () => fetchDealers(dealerListKey),
    onSuccess: data => {
      if (user) {
        const dealerPreferenceID = Number(ApiInstance.dealerId || user.dealer_id);
        const locationPreferenceID = Number(ApiInstance.locationId || user.dealer_location_id);

        const dealer = data.find((d: Dealer) => d.id === dealerPreferenceID);

        queryClient.setQueryData(dealerListKey, data);
        queryClient.setQueryData([queryKeys.dealer.details], dealer);
        queryClient.setQueryData([queryKeys.dealer.id], dealerPreferenceID);
        queryClient.setQueryData([queryKeys.location.id], locationPreferenceID);
        locationMutation.mutate(locationPreferenceID);
      } else {
        userNotFound();
      }
    }
  });

  const selectDealer = (dealer_id: number) => {
    if (user) {
      const dealers = queryClient.getQueryData(dealerListKey);
      const dealer = (dealers as Dealer[])?.find(d => dealer_id === d.id);

      queryClient.setQueryData([queryKeys.dealer.details], dealer);
      queryClient.setQueryData([queryKeys.dealer.id], dealer_id);

      let selectedLocation: DealerLocation | undefined;
      if (dealer?.locations?.length) {
        if (user.my_locations?.length) {
          selectedLocation = dealer.locations.find(l => l.active && (user.dealer_location_id === l.id || user.my_locations?.includes(l.id)));
        } else if (user.location_ids?.length) {
          selectedLocation = dealer.locations.find(l => l.active && (user.dealer_location_id === l.id || user.location_ids?.includes(l.id)));
        } else {
          selectedLocation = dealer.locations.find(l => l.active && user.dealer_location_id === l.id);
          if (!selectedLocation) selectedLocation = dealer.locations.find(l => l.active);
        }
        if (selectedLocation) {
          locationMutation.mutate(selectedLocation.id);
          ApiInstance.locationId = selectedLocation.id.toString();
        }
      }
    } else {
      userNotFound();
    }
  };

  const locations = useMemo(() => {
    if (dealers) {
      return dealers.flatMap((dealer: Dealer) => dealer.locations || []);
    }
    return [];
  }, [dealers]);

  const { data: selectedLocation }: { data: DealerLocation | undefined } = useQuery({
    queryKey: [{ ...queryKeys.location.details, params: { id: locationId } }]
  });

  const isFeatureEnabled = (feature: string | null) => {
    if (!feature || !selectedLocation) return false;

    const VERSION = selectedLocation.version?.features || [];
    const vI = VERSION.findIndex(v => v.name === feature);
    return vI > -1 && VERSION[vI].active;
  };

  const locationDropdownOptions = locations
    ?.filter(l => l.active && l.dealer_id === selectedDealer?.id)
    .filter(l => user?.role_id !== ROLES.WarrantyManager || user.location_ids?.includes(l.id))
    .map(loc => ({
      key: loc.id,
      value: loc.id,
      text: loc.name
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const isKeyLoopLocation = selectedLocation?.dms_id && [DMS.KeyLoopJobs, DMS.KeyLoopMenus].includes(selectedLocation.dms_id);
  const isNextLaneLocation = selectedLocation?.dms_id && DMS.NextLane === selectedLocation.dms_id;

  return {
    dealerMutation,
    locationMutation,
    selectDealer,
    selectedDealer,
    selectedLocation,
    dealers: dealers || [],
    locations,
    locationDropdownOptions,
    isFeatureEnabled,
    isKeyLoopLocation,
    isNextLaneLocation
  };
};
