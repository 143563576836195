import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "semantic-ui-react";

import { AppointmentNoteModal, DetailsCards, TabData, useCan } from "components";
import { CarNoteModal } from "components/CarNoteModal";
import { DMSNotes, NoteRow, NoteTypeIcon } from "components/Notes";
import { APPOINTMENT_NOTE_TYPES, Appointment, AppointmentNote, CAR_NOTE_TYPE, CarNote, STATUS_IDENTIFIER } from "models";
import "modules/AppointmentDetails/components/AppointmentNotes/AppointmentNotes.scss";
import { useGetDropdownOptions } from "modules/AppointmentDetails/components/AppointmentNotes/hooks";
import { ITranslation } from "util/interfaces";

type AppointmentNotesProps = {
  appointment: Appointment;
  isExpanded: boolean;
  tabData: TabData;
};

export const AppointmentNotes = ({ appointment, tabData, isExpanded }: AppointmentNotesProps) => {
  if (!appointment) return null;
  const t = useTranslation().t as ITranslation;
  const { noteTypeOptions } = useGetDropdownOptions({ appointment, t });

  const [showCardDetails, setShowCardDetails] = useState(isExpanded);

  const [selectedAppointmentNote, setSelectedAppointmentNote] = useState<AppointmentNote | null>(null);
  const [selectedCarNote, setSelectedCarNote] = useState<CarNote | null>(null);

  const [noteType, setNoteType] = useState<number | null>(null);
  const canCreateNotes = useCan("create", ["appointment-notes", "appointment-notes-attachments", "appointment-notes-wo", "appointment-notes-parking-location"]);

  useEffect(() => {
    setShowCardDetails(isExpanded);
  }, [isExpanded]);

  const toggleCardDetails = () => {
    setShowCardDetails(prevState => !prevState);
  };

  useEffect(() => {
    setNoteType(selectedAppointmentNote?.appointment_note_type_id || 0);
  }, [selectedAppointmentNote]);

  useEffect(() => {
    if (!noteType) setNoteType(null);
    else if (
      [
        APPOINTMENT_NOTE_TYPES.BillNote,
        APPOINTMENT_NOTE_TYPES.Main,
        APPOINTMENT_NOTE_TYPES.BillNote,
        APPOINTMENT_NOTE_TYPES.RecurringCar,
        APPOINTMENT_NOTE_TYPES.CallCustomer,
        APPOINTMENT_NOTE_TYPES.Attachment,
        APPOINTMENT_NOTE_TYPES.Parking
      ].includes(noteType)
    ) {
      const note = appointment.notes?.find(note => note.appointment_note_type_id === noteType);

      if (note) setSelectedAppointmentNote(note);
    }
  }, [noteType]);

  const handleSelectAppointmentNote = (note: AppointmentNote | null) => {
    setSelectedAppointmentNote(note);
  };

  const handleSelectCarNote = (note: CarNote | null) => {
    setSelectedCarNote(note);
  };

  const handleUnselectNote = () => {
    setSelectedAppointmentNote(null);
    setSelectedCarNote(null);
  };

  const { notes } = appointment;
  const dmsNotes = notes?.filter(note => note.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.DmsNote) || [];
  const dmsCarNotes = appointment.car?.notes?.filter((note: CarNote) => note.car_note_type_id === CAR_NOTE_TYPE.DMS) || [];

  const defaultCarNotes = appointment.car?.notes?.filter((note: CarNote) => note.visible_on_wo && note.car_note_type_id !== CAR_NOTE_TYPE.DMS) || [];

  const dmsAppointmentAndCarNotesLength = (dmsNotes?.length || 0) + (dmsCarNotes?.length || 0);

  const importantNotes = notes?.filter(note => [APPOINTMENT_NOTE_TYPES.Main, APPOINTMENT_NOTE_TYPES.CallCustomer].includes(note.appointment_note_type_id));
  const receptionistRemarks = notes?.filter(
    note => ![APPOINTMENT_NOTE_TYPES.Main, APPOINTMENT_NOTE_TYPES.CallCustomer, APPOINTMENT_NOTE_TYPES.DmsNote].includes(note.appointment_note_type_id)
  );

  if (noteTypeOptions.length <= 0) return null;

  return (
    <DetailsCards
      id={tabData.id}
      title={`${t("notes").message || "Notes"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
      icon="note sticky"
      showExpandArrow={false}
      rightContent={
        appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && canCreateNotes ? (
          <>
            <Icon className={`chevron ${showCardDetails ? "down" : "right"} pointer`} onClick={toggleCardDetails} />
            <Dropdown
              icon={null}
              trigger={
                <div className="add-note-container">
                  <Icon className="plus" color="green" />
                </div>
              }
              selectOnBlur={false}
              value={noteType as number}
              options={noteTypeOptions.map(option => ({
                ...option,
                icon: () => {
                  if (option?.value === APPOINTMENT_NOTE_TYPES.BackOrder) return <div className="custom-back-order-dropdown-icon">BO</div>;
                  return <NoteTypeIcon type={option?.value} />;
                }
              }))}
              onChange={(_, { value }) => setNoteType(value as number)}
            />
          </>
        ) : null
      }
    >
      <div className="AppointmentNotes">
        <div className="appointment-notes-content">
          {!!importantNotes?.length &&
            importantNotes.map(note => {
              return <NoteRow appointmentNote={note} onSelectNote={() => handleSelectAppointmentNote(note)} key={note.id} />;
            })}

          {!!receptionistRemarks?.length && showCardDetails && (
            <div className="receptionist-remarks-container">
              {receptionistRemarks.map(note => {
                return <NoteRow appointmentNote={note} onSelectNote={() => handleSelectAppointmentNote(note)} key={note.id} />;
              })}
            </div>
          )}

          {!!defaultCarNotes?.length &&
            showCardDetails &&
            defaultCarNotes.map(note => {
              return <NoteRow carNote={note} isCarNote onSelectNote={() => handleSelectCarNote(note)} key={note.id} />;
            })}

          {dmsAppointmentAndCarNotesLength > 0 && showCardDetails && (
            <div className="dms-remarks-container">
              <DMSNotes dmsNotes={dmsNotes} dmsCarNotes={dmsCarNotes} />
            </div>
          )}
        </div>

        {(!!noteType || !!selectedAppointmentNote) && (
          <AppointmentNoteModal
            noteType={noteType}
            selectedNote={selectedAppointmentNote}
            onUnselectNote={handleUnselectNote}
            appointment={appointment}
            onSetSelectedAppointmentNote={handleSelectAppointmentNote}
            resetNoteType={() => setNoteType(null)}
          />
        )}

        {!!selectedCarNote && appointment.car && (
          <CarNoteModal
            noteType={noteType}
            selectedNote={selectedCarNote}
            onUnselectNote={handleUnselectNote}
            car={appointment.car}
            onSetSelectedCarNote={handleSelectCarNote}
            resetNoteType={() => setNoteType(null)}
          />
        )}
      </div>
    </DetailsCards>
  );
};
