import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { Checkbox, CheckboxProps, FormField, FormFieldProps } from "semantic-ui-react";

type TypeFormCheckbox<T extends FieldValues> = {
  name: Path<T>;
  checkboxProps?: CheckboxProps;
  formFieldProps?: FormFieldProps;
};

export const FormCheckbox = <T extends FieldValues>({ name, checkboxProps, formFieldProps }: TypeFormCheckbox<T>) => {
  const methods = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field, fieldState: { invalid, isTouched } }) => (
        <FormField {...formFieldProps} error={invalid && isTouched}>
          <Checkbox {...checkboxProps} checked={field.value || false} onChange={(_, data) => field.onChange(data.checked)} />
        </FormField>
      )}
    />
  );
};
