import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm, Images, Videos } from "components";
import { Appointment, QuestionResult, QuestionResultImage, QuestionResultVideo, STATUS_IDENTIFIER } from "models";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type Props = {
  questionResult: QuestionResult;
  appointment: Appointment;
  canUpdate: boolean;
};

export const QuestionResultMedia = ({ canUpdate, appointment, questionResult }: Props) => {
  const t = useTranslation().t as ITranslation;
  const [selectedDeleteImage, setSelectedDeleteImage] = useState<QuestionResultImage | null>(null);
  const [selectedDeleteVideo, setSelectedDeleteVideo] = useState<{ data: QuestionResultVideo; index: number } | null>(null);
  const { updateCheckQuestionItem } = useChecklist(questionResult.appointment_id);

  const images = questionResult?.images?.filter(item => item.active) || [];
  const videos = questionResult?.video?.filter(item => item.active) || [];

  const handleChangeVisibilityImage = (image: QuestionResultImage) => {
    const updatedQuestionResult = {
      ...questionResult,
      images: questionResult.images.map(imgEl => {
        return imgEl.id === image.id ? { ...imgEl, visible_in_pdf: !image.visible_in_pdf } : imgEl;
      })
    } as QuestionResult;

    updateCheckQuestionItem.mutate({ data: updatedQuestionResult });
  };

  const handleDeleteImage = (image: QuestionResultImage) => {
    const updatedQuestionResult = {
      ...questionResult,
      images: questionResult.images.map(imgEl => {
        return imgEl.id === image.id ? { ...imgEl, active: false } : imgEl;
      })
    } as QuestionResult;

    updateCheckQuestionItem.mutate({ data: updatedQuestionResult });
    setSelectedDeleteImage(null);
  };

  const updateVideoInQuestionItem = (updatedVideo: QuestionResultVideo, index: number) => {
    const updatedQuestionResult = {
      ...questionResult,
      video: [...questionResult.video].map((video, i) => {
        return i === index ? updatedVideo : video;
      })
    } as QuestionResult;

    updateCheckQuestionItem.mutate({ data: updatedQuestionResult as QuestionResult });
  };

  const handleDeleteVideo = (video: QuestionResultVideo, index: number) => {
    updateVideoInQuestionItem({ ...video, active: false }, index);
    setSelectedDeleteVideo(null);
  };

  const handleVideoUpload = (video: QuestionResultVideo, index: number, url: string) => {
    updateVideoInQuestionItem({ ...video, url }, index);
  };

  const handleVideoVisibility = (video: QuestionResultVideo, index: number) => {
    updateVideoInQuestionItem({ ...video, visible: !video.visible }, index);
  };

  const handleCancelDeleteMedia = () => {
    setSelectedDeleteImage(null);
    setSelectedDeleteVideo(null);
  };

  const handleConfirmDeleteMedia = () => {
    if (selectedDeleteImage) handleDeleteImage(selectedDeleteImage);
    if (selectedDeleteVideo) handleDeleteVideo(selectedDeleteVideo.data, selectedDeleteVideo.index);
  };

  const onSetSelectVideo = (data: QuestionResultVideo, index: number) => {
    setSelectedDeleteVideo({ data, index });
  };

  return (
    <>
      <div className="checklist-media">
        <Images data={images} onDeleteRequest={setSelectedDeleteImage} canDelete={canUpdate} onChangeVisibility={handleChangeVisibilityImage} />
        <Videos
          appointment={appointment}
          data={videos}
          isEditable={appointment.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && canUpdate}
          handleDeleteVideo={onSetSelectVideo}
          handleVideoUpload={handleVideoUpload}
          handleVideoVisibility={handleVideoVisibility}
        />
      </div>
      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        handleCancel={handleCancelDeleteMedia}
        isOpen={!!(selectedDeleteImage || selectedDeleteVideo)}
        isLoading={updateCheckQuestionItem.isPending}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
        handleConfirm={handleConfirmDeleteMedia}
      />
    </>
  );
};
