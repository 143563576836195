import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import { DetailsCards } from "components";
import printJS from "lib/print-js";
import { Appointment } from "models";
import "modules/AppointmentDetails/components/AcsesReceiverCommunicationLog/AcsesReceiverCommunicationLog.scss";
import { AcsesCommunicationEvents } from "modules/AppointmentDetails/components/AcsesReceiverCommunicationLog/components/AcsesCommunicationEvents";
import { AppointmentInfo } from "modules/AppointmentDetails/components/CommunicationLog/components";
import { ITranslation } from "util/interfaces";

type AcsesReceiverCommunicationLogProps = {
  appointment: Appointment;
};

export const AcsesReceiverCommunicationLog = ({ appointment }: AcsesReceiverCommunicationLogProps) => {
  const t = useTranslation().t as ITranslation;
  const [showAcsesReceiverModal, setShowAcsesReceiverModal] = useState(false);

  const { acses_communication } = appointment;
  if (!acses_communication) return null;

  const handleToggleIsModalOpen = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setShowAcsesReceiverModal(!showAcsesReceiverModal);
  };

  const handlePrint = () => {
    printJS({
      printable: "AcsesReceiverCommunicationLog-Content",
      type: "html",
      copyStyles: true,
      css: ["./AcsesReceiverCommunicationLog.scss"],
      scanStyles: false,
      targetStyles: ["*"]
    });
  };

  return (
    <>
      <div className="AcsesReceiverCommunicationLog" onClick={handleToggleIsModalOpen}>
        <DetailsCards title={t("v8_acses_receiver_log").message || "Acses receiver log"} icon="user" rightContent={null} showExpandArrow={false} button />
      </div>
      <Modal open={showAcsesReceiverModal}>
        <Modal.Header className="AcsesReceiverCommunicationLogHeader">
          <div className="header-title">
            <Icon name="list" />
            <div>{t("v8_acses_receiver_log").message || "Acses receiver log"}</div>
          </div>
          <Button onClick={handlePrint} className="action-btn" color="green">
            {t("v8_print").message || "Print"}
            <Icon className="print" />
          </Button>
        </Modal.Header>

        <Modal.Content id="AcsesReceiverCommunicationLog-Content" className="AcsesReceiverCommunicationLogContent" scrolling>
          <AppointmentInfo appointment={appointment} />
          {acses_communication.events && acses_communication.events.length > 0 && <AcsesCommunicationEvents events={acses_communication.events} />}
        </Modal.Content>

        <Modal.Actions className="AcsesReceiverCommunicationLogActions">
          <Button className="action-btn" color="grey" onClick={handleToggleIsModalOpen} floated="right">
            {t("v8_close").message || "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
