import { createContext, useContext, useEffect, useState } from "react";

interface NavAction {
  onClick: () => void;
}

interface NavActionsContextType {
  action: NavAction | null;
  setAction: (action: NavAction | null) => void;
}

const NavActionsContext = createContext<NavActionsContextType>({
  action: null,
  setAction: () => {}
});

export const NavActionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [action, setAction] = useState<NavAction | null>(null);
  return <NavActionsContext.Provider value={{ action, setAction }}>{children}</NavActionsContext.Provider>;
};

export const useRegisterNavAction = (onClick: () => void) => {
  const { setAction } = useContext(NavActionsContext);

  useEffect(() => {
    setAction({ onClick });
    return () => setAction(null);
  }, [onClick]);
};

export const useNavAction = () => {
  const { action } = useContext(NavActionsContext);
  return action;
};
