import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import ENV from "config/Env";
import ApiInstance from "util/Api";

type UseUploadCarNoteAttachmentRequest = {
  formData: FormData;
};

type UseUploadCarNoteAttachment = {
  isUploadingCarAttachment: boolean;
  attachmentUrl: string;
  handleUploadCarNoteAttachment: ({ formData }: UseUploadCarNoteAttachmentRequest) => Promise<string>;
};

export const useUploadCarNoteAttachment = (): UseUploadCarNoteAttachment => {
  const [attachmentUrl, setAttachmentUrl] = useState("");

  const mutationFn = async ({ formData }: UseUploadCarNoteAttachmentRequest) => {
    const response = await ApiInstance.post("/notes/attachments/upload", formData, ENV.carBaseUrl);

    return response.data.url as string;
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess: (response: string) => setAttachmentUrl(response)
  });

  return { attachmentUrl, isUploadingCarAttachment: mutation.isPending, handleUploadCarNoteAttachment: mutation.mutateAsync };
};
