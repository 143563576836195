import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { DayplannerAppointment } from "modules/Dayplanner/interfaces";
import { DayplannerKeys } from "modules/Dayplanner/queryKeys";
import ApiInstance from "util/Api";

type ReassignMutationData = {
  appointment_id: number;
  user_id: number;
  order: number;
};

export const useReassignMutation = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: ReassignMutationData) => ApiInstance.post("/dayplanner/appointments/reassign", data, ENV.appointmentBaseURL),
    onMutate: data => {
      if (!selectedLocation?.id) throw new Error("invalid location");
      const queryKey = DayplannerKeys.appointments({ dealer_location_id: selectedLocation.id });

      const appointments = queryClient.getQueryData<DayplannerAppointment[]>(queryKey);
      if (!appointments) return [];

      const appointment = appointments.find(app => app.id === data.appointment_id);
      if (!appointment) return appointments;

      const mechanicId = appointment.assigned_mechanic;
      const mechanicOrder = appointment.assigned_mechanic_order;

      if (!mechanicId || !mechanicOrder) return;

      const updatedAppointments = appointments.map(app => {
        if (app.id === data.appointment_id) return { ...app, assigned_mechanic: data.user_id, assigned_mechanic_order: data.order };

        if (!app.assigned_mechanic_order) return app;

        if (mechanicId !== data.user_id) {
          if (app.assigned_mechanic === mechanicId && app.assigned_mechanic_order > mechanicOrder)
            return { ...app, assigned_mechanic_order: app.assigned_mechanic_order - 1 };

          if (app.assigned_mechanic === data.user_id && app.assigned_mechanic_order >= data.order)
            return { ...app, assigned_mechanic_order: app.assigned_mechanic_order + 1 };
        } else {
          if (mechanicOrder > data.order) {
            if (app.assigned_mechanic === mechanicId && app.assigned_mechanic_order >= data.order && app.assigned_mechanic_order < mechanicOrder)
              return { ...app, assigned_mechanic_order: app.assigned_mechanic_order + 1 };
          } else {
            if (app.assigned_mechanic === mechanicId && app.assigned_mechanic_order <= data.order && app.assigned_mechanic_order > mechanicId)
              return { ...app, assigned_mechanic_order: app.assigned_mechanic_order - 1 };
          }
        }

        return app;
      });

      queryClient.setQueryData<DayplannerAppointment[]>(queryKey, updatedAppointments);

      return appointments;
    },
    onError: (error, _, appointments) => {
      toast.error(error.message);
      if (selectedLocation?.id) queryClient.setQueryData<DayplannerAppointment[]>(DayplannerKeys.appointments({ dealer_location_id: selectedLocation.id }), appointments);
    }
  });

  return mutation.mutate;
};
