import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { Appointment, CarNote, CarNoteAttachment } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import { useCarDetails } from "modules/CarDetails/hooks/useCarDetails";
import { CarDetailsKeys } from "modules/CarDetails/queryKeys";
import ApiInstance from "util/Api";

type UseCarNoteRequest = {
  payload: Record<string, string | number | undefined | null | Date | boolean | Partial<CarNoteAttachment> | Partial<CarNoteAttachment>[]>;
};

export const useCarNotes = (id: number, appointment_id: number) => {
  const queryClient = useQueryClient();
  const carDetailsViewQueryKey = CarDetailsKeys.details(id);
  const appointmentDetailsViewKey = AppointmentDetailsKeys.view(appointment_id);
  const { car } = useCarDetails(id);

  const addNote = async ({ payload }: UseCarNoteRequest): Promise<number> => {
    const response = await ApiInstance.post("/notes/create", payload, ENV.carBaseUrl);
    return response.data.car_note_id;
  };

  const updateAppointmentData = (notes: CarNote[]) => {
    if (!appointmentDetailsViewKey) return;
    const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
    if (!appointment?.car) return;

    queryClient.setQueryData(appointmentDetailsViewKey, {
      ...appointment,
      car: {
        ...appointment.car,
        notes
      }
    });
  };

  const addCarNote = useMutation({
    mutationFn: addNote,
    onMutate: () => {
      const prevNotesState = [...(car?.notes || [])];
      return { prevNotesState };
    },
    onSuccess: (id: number, variables) => {
      const note = { id, ...variables.payload } as CarNote;
      if (car.notes?.some((n: CarNote) => n.id === id)) return;
      const updatedNotes = [...(car.notes ?? []), note];
      queryClient.setQueryData(carDetailsViewQueryKey, { ...car, notes: updatedNotes });
      updateAppointmentData(updatedNotes);
    },
    onError: (error, _, context) => {
      toast.error(error.message);
      queryClient.setQueryData(carDetailsViewQueryKey, { ...car, notes: context?.prevNotesState });
    }
  });

  const updateNote = async ({ payload }: UseCarNoteRequest) => {
    const response = await ApiInstance.post("/notes/update", payload, ENV.carBaseUrl);
    return response.data;
  };

  const updateCarNote = useMutation({
    mutationFn: updateNote,
    onMutate: params => {
      const notes = [...(car?.notes || [])];
      const noteIdx = notes.findIndex(n => n.id === params.payload.id);
      const updatedNotes = notes.with(noteIdx, { ...notes[noteIdx], ...params.payload });
      if (noteIdx < 0) return;
      queryClient.setQueryData(carDetailsViewQueryKey, { ...car, notes: updatedNotes });
      updateAppointmentData(updatedNotes);
      return { notes };
    },
    onError: (error, _variables, context) => {
      toast.error(error.message);
      if (context?.notes) queryClient.setQueryData(carDetailsViewQueryKey, { ...car, notes: context?.notes });
    }
  });

  const deleteNote = async ({ noteID }: { noteID: number }) => {
    await ApiInstance.post("/notes/delete", { car_note_id: noteID }, ENV.carBaseUrl);
  };

  const deleteCarNote = useMutation({
    mutationFn: deleteNote,
    onMutate: params => {
      const notes = [...(car?.notes || [])];
      const updatedNotes = notes.filter((e: CarNote) => e.id !== params.noteID);
      queryClient.setQueryData(carDetailsViewQueryKey, {
        ...car,
        notes: updatedNotes
      });
      updateAppointmentData(updatedNotes);
      return { notes };
    },
    onError: (error, _variables, context) => {
      toast.error(error.message);
      if (context?.notes) queryClient.setQueryData(carDetailsViewQueryKey, { ...car, notes: context?.notes });
    }
  });

  return { addCarNote, updateCarNote, deleteCarNote };
};
