import { QueryFunctionContext, useQueries, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ReportData } from "models";
import { LocationReportQueryKeys } from "modules/LocationReports/queryKeys";
import ApiInstance from "util/Api";
import { translateReportForCharts } from "util/common";
import { ITranslation } from "util/interfaces";
import { IBackendQueryKey } from "util/keyFactory";

interface UseLocationReportsParams {
  dealer_location_id: number;
  date_from: string;
  date_to: string;
}

export const useLocationReports = ({ dealer_location_id, date_from, date_to }: UseLocationReportsParams) => {
  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [reportsSequentialLoader, setReportsSequentialLoader] = useState<boolean[]>([]);

  const initialGroupSetRef = useRef(false);
  const lastValidDatesRef = useRef<{ date_from: string; date_to: string } | null>(null);

  const t = useTranslation().t as ITranslation;

  if (date_from && date_to) lastValidDatesRef.current = { date_from, date_to };
  const datesForQuery = lastValidDatesRef.current;

  const getLocationReportGroups = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, params, endpoint } = queryKey[0];
    try {
      const response = await ApiInstance.post(endpoint, { ...params }, baseUrl);
      setSelectedGroup(response.data.groups[0]);
      return response.data.groups as number[];
    } catch (err) {
      toast.error(t("failed_to_load_report_groups").message || "Failed to load report groups");
      throw err;
    }
  };

  const getLocationReportList = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, params, endpoint } = queryKey[0];
    try {
      const response = await ApiInstance.post(endpoint, { ...params }, baseUrl);
      const template_ids = response.data.template_ids || [];
      setReportsSequentialLoader(template_ids.map((_: number, index: number) => index === 0));
      return template_ids as number[];
    } catch (err) {
      toast.error(t("failed_to_load_report").message || "Failed to load report");
      throw err;
    }
  };

  const getLocationReport = async ({ signal, queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>, index: number) => {
    const { baseUrl, params, endpoint } = queryKey[0];
    try {
      const response = await ApiInstance.post(endpoint, params, baseUrl, { signal });
      const report = response.data;
      const parsedReportData = report.report_data ? report.report_data.map((d: string) => JSON.parse(d)) : [];
      const parsedReportThreshold = report.thresholds ? JSON.parse(report.thresholds) : [];

      const translatedData = parsedReportData.map((d: string[]) => translateReportForCharts(d, t));

      if (index < reportsSequentialLoader.length - 1) setReportsSequentialLoader(prev => prev.with(index + 1, true));

      return { ...response.data, report_data: translatedData, thresholds: parsedReportThreshold } as ReportData;
    } catch (err) {
      if (index < reportsSequentialLoader.length - 1) setReportsSequentialLoader(prev => prev.with(index + 1, true));

      toast.error(t("failed_to_load_report").message || "Failed to load report");
      throw err;
    }
  };

  const groups = useQuery({
    queryKey: LocationReportQueryKeys.list_groups(dealer_location_id),
    queryFn: getLocationReportGroups,
    enabled: !!dealer_location_id,
    staleTime: Infinity
  });

  useEffect(() => {
    if (groups.data && groups.data.length > 0 && !selectedGroup && !initialGroupSetRef.current) {
      initialGroupSetRef.current = true;
      setSelectedGroup(groups.data[0]);
    }
  }, [groups.data, selectedGroup]);

  const reportList = useQuery({
    queryKey: LocationReportQueryKeys.list_reports(dealer_location_id, Number(selectedGroup)),
    queryFn: getLocationReportList,
    enabled: !!selectedGroup && !!dealer_location_id,
    staleTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  useEffect(() => {
    if (reportList.isSuccess && reportList.data && reportList.data?.length > 0 && reportsSequentialLoader.length === 0) {
      setReportsSequentialLoader(reportList.data.map(() => false));
    }
  }, [reportList.isSuccess, reportList.data, reportsSequentialLoader.length]);

  const reports = useQueries({
    queries:
      reportList.data?.map((report_id: number, index: number) => ({
        queryKey: LocationReportQueryKeys.location_report(index, dealer_location_id, report_id, datesForQuery?.date_from, datesForQuery?.date_to),
        queryFn: (queryFnParams: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => getLocationReport(queryFnParams, index),
        enabled: !!report_id && !!datesForQuery && (index === 0 || (index > 0 && reportsSequentialLoader[index - 1]))
      })) ?? []
  }).sort((a, b) => (a.data?.report_order || 0) - (b.data?.report_order || 0));

  return {
    groups,
    selectedGroup,
    setSelectedGroup,
    reportList,
    reports,
    loading: groups.isLoading || reportList.isLoading,
    error: groups.isError || reportList.isError
  };
};
