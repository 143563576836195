import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from "react";

import { DealersLocationsDropdownValue } from "components";
import { useDealersLocations, useUser } from "hooks";
import { DOCUMENTATION_CATEGORIES, DealerDocumentation, ROLES } from "models";

type DealerDocumentationContextType = {
  visibleToMechanic: boolean;
  categoryId: DOCUMENTATION_CATEGORIES;
  selectedDealersLocations: DealersLocationsDropdownValue;
  showAddEditDealerDocumentationModal: DealerDocumentation | null;
  showViewModalDocumentation: DealerDocumentation | null;
  handleSetVisibleToMechanic: Dispatch<SetStateAction<boolean>>;
  handleSetCategoryId: Dispatch<SetStateAction<DOCUMENTATION_CATEGORIES>>;
  handleSetSelectedDealersLocations: Dispatch<SetStateAction<DealersLocationsDropdownValue>>;
  handleSetShowAddEditDealerDocumentationModal: Dispatch<SetStateAction<DealerDocumentation | null>>;
  handleSetShowViewModalDocumentation: Dispatch<SetStateAction<DealerDocumentation | null>>;
};

const DealerDocumentationsContext = createContext<DealerDocumentationContextType>({
  categoryId: DOCUMENTATION_CATEGORIES.Manual,
  selectedDealersLocations: { dealerIds: [], locationIds: [] },
  visibleToMechanic: true,
  showAddEditDealerDocumentationModal: null,
  showViewModalDocumentation: null,
  handleSetCategoryId: () => {},
  handleSetSelectedDealersLocations: () => {},
  handleSetVisibleToMechanic: () => {},
  handleSetShowAddEditDealerDocumentationModal: () => {},
  handleSetShowViewModalDocumentation: () => {}
});

const useDealerDocumentationContext = () => useContext(DealerDocumentationsContext);

const DealerDocumentationsProvider = ({ children }: PropsWithChildren) => {
  const user = useUser();
  const { selectedDealer, selectedLocation } = useDealersLocations();

  const [visibleToMechanic, setVisibleToMechanic] = useState(true);
  const [categoryId, setCategoryId] = useState<DOCUMENTATION_CATEGORIES>(DOCUMENTATION_CATEGORIES.Manual);
  const [showViewModalDocumentation, setShowViewModalDocumentation] = useState<DealerDocumentation | null>(null);
  const [showAddEditDealerDocumentationModal, setShowAddEditDealerDocumentationModal] = useState<DealerDocumentation | null>(null);
  const [selectedDealersLocations, setSelectedDealersLocations] = useState<DealersLocationsDropdownValue>({ dealerIds: [], locationIds: [] });

  useEffect(() => {
    if (selectedLocation && selectedDealer) {
      const isMechanicRole = user?.role_id === ROLES.Mechanic;
      setSelectedDealersLocations({ dealerIds: isMechanicRole ? [] : [selectedDealer.id], locationIds: isMechanicRole ? [selectedLocation.id] : [] });
    }
  }, [selectedLocation, selectedDealer]);

  return (
    <DealerDocumentationsContext.Provider
      value={{
        categoryId,
        visibleToMechanic,
        selectedDealersLocations,
        showViewModalDocumentation,
        showAddEditDealerDocumentationModal,
        handleSetCategoryId: setCategoryId,
        handleSetVisibleToMechanic: setVisibleToMechanic,
        handleSetSelectedDealersLocations: setSelectedDealersLocations,
        handleSetShowViewModalDocumentation: setShowViewModalDocumentation,
        handleSetShowAddEditDealerDocumentationModal: setShowAddEditDealerDocumentationModal
      }}
    >
      {children}
    </DealerDocumentationsContext.Provider>
  );
};

export { DealerDocumentationsProvider, useDealerDocumentationContext };
