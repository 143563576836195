import { QueryFunctionContext, useQueryClient } from "@tanstack/react-query";

import { WebSocketMessageListener } from "components";
import { useRealTimeQuery } from "hooks";
import { Car, CarNote } from "models";
import { CarDetailsKeys } from "modules/CarDetails/queryKeys";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export const useCarDetails = (id: number) => {
  const queryClient = useQueryClient();
  const carDetailsQueryKey = CarDetailsKeys.details(id);

  const updateCarData = (updateFn: (car: Car) => Car) => {
    const car = queryClient.getQueryData<Car>(carDetailsQueryKey);
    if (!car) return;
    queryClient.setQueryData(carDetailsQueryKey, updateFn(car));
  };

  const listeners: WebSocketMessageListener[] = [
    {
      model: "Car",
      action: "update",
      id: Number(id),
      callback: message => {
        updateCarData(car => {
          return { ...car, ...(message.data as Car) } as Car;
        });
      }
    },
    {
      model: "CarNote",
      action: "create",
      filter: { car_id: Number(id) },
      callback: message => {
        updateCarData(car => {
          const note = message.data as CarNote;
          if (car.notes?.some(n => n.id === note.id)) return car;
          return { ...car, notes: [...(car.notes ?? []), note] } as Car;
        });
      }
    },
    {
      model: "CarNote",
      action: "update",
      filter: { car_id: Number(id) },
      callback: message => {
        updateCarData(car => {
          if (!car.notes?.length) return car;
          const note = message.data as CarNote;
          const noteIdx = car.notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return car;
          return { ...car, notes: car.notes.with(noteIdx, { ...car.notes[noteIdx], ...note }) } as Car;
        });
      }
    },
    {
      model: "CarNote",
      action: "upsert",
      filter: { car_id: Number(id) },
      callback: message => {
        updateCarData(car => {
          const note = message.data as CarNote;
          const notes = [...(car.notes ?? [])];
          const noteIdx = notes.findIndex(n => n.id === note.id);
          if (noteIdx >= 0) notes[noteIdx] = { ...notes[noteIdx], ...note };
          else notes.push(note);
          return { ...car, notes } as Car;
        });
      }
    },
    {
      model: "CarNote",
      action: "delete",
      filter: { car_id: Number(id) },
      callback: message => {
        updateCarData(car => {
          if (!car.notes?.length) return car;
          const note = message.data as CarNote;
          const noteIdx = car.notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return car;
          return { ...car, notes: car.notes.slice(0, noteIdx).concat(car.notes.slice(noteIdx + 1)) } as Car;
        });
      }
    }
  ];

  const getCarDetail = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);
    return res.data;
  };

  const realTimeQuery = useRealTimeQuery({ queryKey: carDetailsQueryKey, queryFn: getCarDetail, listeners });

  return {
    car: realTimeQuery.data,
    loading: realTimeQuery.isFetching,
    error: realTimeQuery.error
  };
};
