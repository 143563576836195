import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ExactOnlineFailure } from "models";
import { ExactFailuresQueryKeys } from "modules/ExactFailures/queryKeys";
import { ExactFailuresFilters } from "modules/ExactFailures/util/types";
import ApiInstance from "util/Api";
import { ITranslation } from "util/interfaces";
import { IBackendQueryKey } from "util/keyFactory";

interface ExactFailuresResponse {
  failures: ExactOnlineFailure[];
  nb_pages: number;
  nb_items: number;
}

export const useExactFailures = (filters: ExactFailuresFilters) => {
  const queryClient = useQueryClient();
  const t = useTranslation().t as ITranslation;
  const lastValidDatesRef = useRef<{ date_from: string | null; date_to: string | null }>({ date_from: null, date_to: null });
  if ((filters.date_from && filters.date_to) || (filters.date_from === null && filters.date_to === null))
    lastValidDatesRef.current = { date_from: filters.date_from, date_to: filters.date_to };
  const datesForQuery = lastValidDatesRef.current;

  const getFailures = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, params, endpoint } = queryKey[0];
    try {
      const response = await ApiInstance.post(endpoint, params, baseUrl);
      return response.data as ExactFailuresResponse;
    } catch (_err) {
      toast.error(t("v8_failed_to_load_failures").message || "Failed to load exact failures");
      return {
        failures: [],
        nb_pages: 0,
        nb_items: 0
      } as ExactFailuresResponse;
    }
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ExactFailuresQueryKeys.failures({ ...filters, date_from: datesForQuery?.date_from, date_to: datesForQuery?.date_to }),
    queryFn: getFailures,
    refetchOnMount: false,
    retry: false,
    staleTime: Infinity,
    enabled: !filters.status_code || (filters.status_code >= 100 && filters.status_code <= 599)
  });

  const invalidateFailures = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ExactFailuresQueryKeys.failures(filters) });
  }, [queryClient, filters]);

  return { data, isLoading, isError, invalidateFailures };
};
