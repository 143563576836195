import { Icon, Label, Modal } from "semantic-ui-react";

import { PublisherInformation, ReadingModalImage } from "modules/DealerDocumentations/components/DealerDocumentationReadingModal/components";
import "modules/DealerDocumentations/components/DealerDocumentationReadingModal/DealerDocumentationReadingModal.scss";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { useDealerCategories } from "modules/DealerDocumentations/hooks";

export const DealerDocumentationReadingModal = () => {
  const { getCategoryName } = useDealerCategories();
  const { showViewModalDocumentation: documentation, handleSetShowViewModalDocumentation } = useDealerDocumentationContext();
  const categoryName = getCategoryName(documentation?.category_id);

  const handleOnClose = () => handleSetShowViewModalDocumentation(null);

  return (
    <Modal className="DealerDocumentationReadingModal" open={!!documentation} size="large">
      <Modal.Header className="dealer-modal-header">
        <div className="document-info">
          <span>{documentation?.title}</span>
          {documentation?.is_pinned && <Icon className="pin" />}
          <Label className="category-name-dealer">{categoryName}</Label>
        </div>
        <button className="exitModal" onClick={handleOnClose}>
          <Icon className="x" size="large" />
        </button>
      </Modal.Header>
      <Modal.Content>
        <PublisherInformation />
        {documentation?.url && <ReadingModalImage url={documentation.url} />}
        <div className="dealer-modal-content">
          <div dangerouslySetInnerHTML={{ __html: documentation?.content || "" }}></div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
