import { ErrorBoundary } from "react-error-boundary";
import { Route, RouteProps } from "react-router-dom";

import { ErrorFallback } from "components";

export const RouteWithErrorBoundary: React.FC<RouteProps> = props => {
  return (
    <ErrorBoundary key={props.location?.pathname} FallbackComponent={ErrorFallback}>
      <Route {...props} />
    </ErrorBoundary>
  );
};
