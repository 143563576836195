import { useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, Icon, Loader } from "semantic-ui-react";

import { TabData, Tabs } from "components";
import { Form } from "components";
import { CommunicationSettings } from "models";
import { DeskCheckIn, DiagnoseOverview, General, Kiosk, OnlineCheckIn, RepairOverview, Support, Variables } from "modules/ComunicationSettings/components";
import "modules/ComunicationSettings/CustomerCom.scss";
import { useCommunicationSettingsMutation, useGetCommunicationSettingsData, useGetPDFTemplates } from "modules/ComunicationSettings/hooks/useComunicationSettings";
import { ITranslation } from "util/interfaces";

const CustomerCom = () => {
  const t = useTranslation().t as ITranslation;
  const { data: communicationSettings, isLoading: isLoadingCommunicationSettings } = useGetCommunicationSettingsData();
  const { data: pdfTemplates, isLoading: isLoadingPDFTemplates } = useGetPDFTemplates();
  const { mutate: updateCommunicationSettings, isPending: isUpdatingCommunicationSettings } = useCommunicationSettingsMutation();
  const onSubmitHandler = (formValues: CommunicationSettings) => {
    updateCommunicationSettings(formValues);
  };
  const tabsData: Map<string, TabData> = useMemo(
    () =>
      new Map([
        ["general", { data: [], id: "general", title: t("v8_general").message || "General" }],
        ["variables", { data: [], id: "variables", title: t("v8_variables").message || "Variables" }],
        ["onlineCheckIn", { data: [], id: "onlineCheckIn", title: t("v8_online_check_in").message || "Online Check in" }],
        ["deskCheckIn", { data: [], id: "deskCheckIn", title: t("v8_desk_check_in").message || "Desk Check in" }],
        ["diagnoseOverview", { data: [], id: "diagnoseOverview", title: t("v8_diagnose_overview").message || "Diagnose Overview" }],
        ["repairOverview", { data: [], id: "repairOverview", title: t("v8_repair_overview").message || "Repair Overview" }],
        ["support", { data: [], id: "support", title: t("v8_support").message || "Support" }],
        ["kiosk", { data: [], id: "kiosk", title: t("v8_kiosk").message || "Kiosk" }]
      ]),
    []
  );

  if (isLoadingCommunicationSettings || isLoadingPDFTemplates) return <Loader />;

  const templatesDropdownOptions = pdfTemplates.map(item => ({
    key: item.id,
    value: item.id,
    text: item.name
  }));
  return (
    <Form<CommunicationSettings>
      onSubmit={onSubmitHandler}
      defaultValues={communicationSettings}
      formProps={{ loading: isLoadingCommunicationSettings, disabled: isUpdatingCommunicationSettings }}
    >
      <div className="customer-com-container">
        <div className="row center">
          <div className="full customer-com-tabs">
            <Tabs data={tabsData} />
          </div>
          <div className="save">
            <Button color="green">
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </div>
        </div>
        <General tabId={tabsData.get("general")?.id as string} />
        <Variables tabId={tabsData.get("variables")?.id as string} />
        <OnlineCheckIn tabId={tabsData.get("onlineCheckIn")?.id as string} />
        <DeskCheckIn tabId={tabsData.get("deskCheckIn")?.id as string} />
        <DiagnoseOverview tabId={tabsData.get("diagnoseOverview")?.id as string} />
        <RepairOverview tabId={tabsData.get("repairOverview")?.id as string} pdfTemplateOptions={templatesDropdownOptions} />
        <Support tabId={tabsData.get("support")?.id as string} />
        <Kiosk tabId={tabsData.get("kiosk")?.id as string} />
      </div>
    </Form>
  );
};

export default withTranslation()(CustomerCom);
