import { Icon, Label } from "semantic-ui-react";

import "components/PinUserLabel/PinUserLabel.scss";
import { PinModel, ROLES } from "models";
import ApiInstance from "util/Api";

type PinUserLabelProps = {
  currentPin: PinModel;
};

export const PinUserLabel = ({ currentPin }: PinUserLabelProps) => {
  const loggedInWithQrCode = ApiInstance.loggedInWithQrCode;

  const getCurrentUserIcon = (isDms: boolean | undefined, userRole: number | undefined) => {
    if (isDms) return <Icon className="desktop dms-bg-color" />;

    if (userRole === ROLES.Mechanic) return <Icon className="wrench mechanic-color" />;
    if (userRole === ROLES.Receptionist) {
      return loggedInWithQrCode ? (
        <>
          <Icon className="wrench chief-mechanic-color" /> <Icon className="sparkle chief-mechanic-color" />
        </>
      ) : (
        <Icon className="pen receptionists-color" />
      );
    }

    if (userRole === ROLES.WarrantyManager) return <Icon className="shield halved" />;

    return null;
  };

  const getUserColors = (userRole: number | undefined) => {
    if (userRole === ROLES.Mechanic) return "mechanic-color mechanic-bg-color";
    if (userRole === ROLES.Receptionist) return loggedInWithQrCode ? "chief-mechanic-color chief-mechanic-bg-color" : "receptionists-color receptionists-bg-color";

    if (userRole === ROLES.WarrantyManager) return "warranty-color warranty-bg-colors";

    return "";
  };
  return (
    <Label className={getUserColors(currentPin.user?.role_id)}>
      {getCurrentUserIcon(currentPin.is_dms, currentPin.user?.role_id)} {currentPin.is_dms ? "DMS " : `${currentPin.user?.first_name} ${currentPin.user?.last_name}`}
    </Label>
  );
};
