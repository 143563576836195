import { useTranslation } from "react-i18next";
import { Label, Popup, SemanticCOLORS } from "semantic-ui-react";

import { PinTypeIcon } from "components";
import "components/Pin/Pin.scss";
import { useDealersLocations } from "hooks";
import { Appointment, DealerLocation, Intervention, PIN_STATUS, PIN_TYPE, PinModel } from "models";
import { PinType } from "modules/AppointmentDetails/components/Interventions/InterventionItem";
import { useAppointment } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

interface PinModelWithHistory extends PinModel {
  history: PinModel[];
}

type PinProps = {
  pin: Partial<PinModelWithHistory>;
  onClick?: () => void;
  pinType: Partial<PinType>;
  enableModal?: boolean;
  intervention?: Intervention;
};

type PinStatusTranslationLookup = {
  [key in PIN_STATUS]: { translation_key: string; default_message: string };
};

const pinStatusTranslations: PinStatusTranslationLookup = {
  [PIN_STATUS.NotSet]: { translation_key: "v8_not_set", default_message: "Not Set" },
  [PIN_STATUS.Deleted]: { translation_key: "v8_deleted", default_message: "Deleted" },
  [PIN_STATUS.Support]: { translation_key: "v8_support", default_message: "Support" },
  [PIN_STATUS.ApprovalPending]: { translation_key: "v8_approval_pending", default_message: "Approval Pending" },
  [PIN_STATUS.MoreInfo]: { translation_key: "v8_more_information_required", default_message: "More Information Required" },
  [PIN_STATUS.Approved]: { translation_key: "v8_approved", default_message: "Approved" },
  [PIN_STATUS.Declined]: { translation_key: "v8_declined", default_message: "Declined" },
  [PIN_STATUS.ToClaim]: { translation_key: "v8_to_claim", default_message: "To Claim" },
  [PIN_STATUS.Claimed]: { translation_key: "v8_claimed", default_message: "Claimed" },
  [PIN_STATUS.Billed]: { translation_key: "v8_billed", default_message: "Billed" },
  [PIN_STATUS.Request]: { translation_key: "v8_request", default_message: "Request" }
};

export const isWarrantyPinSignatureMissing = (
  pin?: Partial<PinModelWithHistory>,
  appointment?: Appointment,
  selectedLocation?: DealerLocation,
  intervention?: Intervention
) => {
  return (
    selectedLocation?.is_warranty_pin_signature_missing_warning_enabled &&
    pin?.pin_type_id === PIN_TYPE.Warranty &&
    intervention &&
    !intervention.is_signed_by_customer &&
    (!selectedLocation?.warranty_pin_signature_brands?.length ||
      selectedLocation.warranty_pin_signature_brands.some(brand => brand.vin_prefix && appointment?.vin_nr?.startsWith(brand.vin_prefix)))
  );
};

export const isAppointmentWarrantyPinSignatureMissing = (appointment: Appointment, location?: DealerLocation) => {
  if (!location?.is_warranty_pin_signature_missing_warning_enabled || !appointment?.warranty_pin_signature_missing_count) return false;
  if (!location?.warranty_pin_signature_brands?.length) return true;
  return location?.warranty_pin_signature_brands.some(brand => brand.vin_prefix && appointment.vin_nr.startsWith(brand.vin_prefix));
};

export const getPinColor = (
  pin?: Partial<PinModelWithHistory>,
  appointment?: Appointment,
  selectedLocation?: DealerLocation,
  intervention?: Intervention
): SemanticCOLORS => {
  if (isWarrantyPinSignatureMissing(pin, appointment, selectedLocation, intervention)) return "red";

  let color = "blue";

  if (pin?.pin_type_id === PIN_TYPE.Warranty) {
    if (selectedLocation?.is_warranty_pin_support_warning_enabled && !pin.support_nr) color = "orange";
    if (selectedLocation?.is_warranty_pin_claim_warning_enabled && !pin.claim_nr) color = "orange";
  }

  if (pin?.pin_type_id === PIN_TYPE.Recall) {
    if (selectedLocation?.is_recall_pin_support_warning_enabled && !pin.support_nr) color = "orange";
    if (selectedLocation?.is_recall_pin_claim_warning_enabled && !pin.claim_nr) color = "orange";
  }

  return color as SemanticCOLORS;
};

export const useAppointmentPinColor = (appointment: Appointment): SemanticCOLORS => {
  const { selectedLocation } = useDealersLocations();
  if (!selectedLocation) return "blue";

  if (isAppointmentWarrantyPinSignatureMissing(appointment, selectedLocation)) return "red";

  if (selectedLocation.is_warranty_pin_support_warning_enabled && appointment.warranty_pin_support_nr_missing_count) return "orange";
  if (selectedLocation.is_warranty_pin_claim_warning_enabled && appointment.warranty_pin_claim_nr_missing_count) return "orange";

  if (selectedLocation.is_recall_pin_support_warning_enabled && appointment.recall_pin_support_nr_missing_count) return "orange";
  if (selectedLocation.is_recall_pin_claim_warning_enabled && appointment.recall_pin_claim_nr_missing_count) return "orange";

  return "blue";
};

export const Pin = ({ intervention, pin, onClick, pinType }: PinProps) => {
  const t = useTranslation().t as ITranslation;
  const { appointment } = useAppointment(Number(pin.appointment_id));
  const { selectedLocation } = useDealersLocations();

  const pinTypeID = pinType?.value || pin.pin_type_id || 0;
  const statusId = pin?.pin_status_id;

  if (statusId && statusId === PIN_STATUS.Deleted) return null;

  const translationKey = typeof statusId === "number" ? pinStatusTranslations[statusId].translation_key : "";
  const defaultMessage = typeof statusId === "number" ? pinStatusTranslations[statusId].default_message : "";

  return (
    <Label color={getPinColor(pin, appointment, selectedLocation, intervention)} onClick={onClick}>
      <Popup
        trigger={
          <div>
            <PinTypeIcon pin_type_id={pinTypeID} /> {intervention?.pin_history?.length}
          </div>
        }
        content={
          <span>
            <strong>{t("v8_pin_status").message || "Pin Status"}:&nbsp;</strong>
            {t(translationKey).message || defaultMessage}
          </span>
        }
      />
    </Label>
  );
};
