import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ColorKeys } from "components";
import { useDealersLocations, useUser } from "hooks";
import { IndicatorFilters } from "modules/Appointments/components";
import { ITranslation } from "util/interfaces";
import { queryKeys } from "util/keyFactory";

interface SpecialIndicatorQueryTypes {
  is_star?: string;
  is_money?: string;
  is_shop?: string;
  is_shop_green?: string;
  is_shop_orange?: string;
  is_shop_blue?: string;
  is_recurring?: string;
  is_count?: string;
  is_shop_red?: string;
  pin_count?: string;
}

const indicatorsFiltrationLogic = (current: IndicatorFilters[], index: number) =>
  current.map((filter, idx) => (idx === index ? { ...filter, active: !filter.active } : filter));

export const useGetSpecialIndicatorsInitialState = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;
  const specialIndicatorsQueryData: SpecialIndicatorQueryTypes = queryClient.getQueryData([{ ...queryKeys.appointments.specialIndicators }])!;

  const defaultCartVisible = !(
    user?.shop_status_same_day_filter_enabled ||
    user?.shop_status_next_day_filter_enabled ||
    user?.shop_status_to_order_filter_enabled ||
    user?.shop_status_backorder_filter_enabled
  );

  const specialIndicators: IndicatorFilters[] = [
    { icon: "is_money", active: !!specialIndicatorsQueryData?.is_money, filter: "is_money" },
    { icon: "is_star", active: !!specialIndicatorsQueryData?.is_star, filter: "is_star" },
    ...(selectedLocation?.pin_visible ? [{ icon: "pin" as ColorKeys, active: !!specialIndicatorsQueryData?.pin_count, filter: "pin_count" }] : []),
    { icon: "triangle exclamation", active: !!specialIndicatorsQueryData?.is_recurring, filter: "is_recurring" }
  ];

  const cartIndicators: IndicatorFilters[] = [
    ...(defaultCartVisible ? [{ icon: "is_shop" as ColorKeys, active: !!specialIndicatorsQueryData?.is_shop, filter: "is_shop" }] : []),
    ...(user?.shop_status_same_day_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_green,
            filter: "is_shop_green",
            borderColor: "green",
            text: t("v8_shopping_cart_same_day").message || "Same day"
          }
        ]
      : []),
    ...(user?.shop_status_next_day_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_orange,
            filter: "is_shop_orange",
            borderColor: "orange-cart",
            text: t("v8_shopping_cart_tomorrow").message || "Tomorrow"
          }
        ]
      : []),
    ...(user?.shop_status_to_order_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_blue,
            filter: "is_shop_blue",
            borderColor: "blue",
            text: t("v8_shopping_cart_to_order").message || "To order"
          }
        ]
      : []),
    ...(user?.shop_status_backorder_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_red,
            filter: "is_shop_red",
            borderColor: "red",
            text: t("v8_shopping_cart_backorder").message || "Backorder"
          }
        ]
      : [])
  ];

  const [indicatorFilters, setIndicatorFilters] = useState<IndicatorFilters[]>(specialIndicators);
  const [cartFilters, setCartFilters] = useState<IndicatorFilters[]>(cartIndicators);

  const updateIndicatorsQueryData = useCallback(() => {
    const indicatorFiltersObj: Record<string, string> = {};
    [...cartFilters, ...indicatorFilters].forEach(item => {
      if (item.active && item.filter) {
        indicatorFiltersObj[item.filter] = item.filter;
      }
    });

    const currentFilterData = queryClient.getQueryData([queryKeys.appointments.specialIndicators]);
    if (JSON.stringify(currentFilterData) === JSON.stringify(indicatorFiltersObj)) return;
    queryClient.setQueryData([queryKeys.appointments.specialIndicators], indicatorFiltersObj);
  }, [cartFilters, indicatorFilters]);

  useEffect(() => {
    updateIndicatorsQueryData();
  }, [updateIndicatorsQueryData]);
  const handleIndicatorFilters = (index: number) => {
    setIndicatorFilters(current => indicatorsFiltrationLogic(current, index));
  };

  const handleCartFilters = (index: number) => {
    setCartFilters(current => indicatorsFiltrationLogic(current, index));
  };

  const handleSelectAllCarts = () => {
    if (cartFilters.every(e => e.active)) return setCartFilters(curr => curr.map(cart => ({ ...cart, active: false })));
    setCartFilters(current => current.map(cart => ({ ...cart, active: true })));
  };
  return { indicatorFilters, handleIndicatorFilters, cartFilters, handleCartFilters, handleSelectAllCarts };
};
