import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { CommunicationSettings } from "models";
import { CommunicationSettingsQueryKeys } from "modules/ComunicationSettings/queryKeys";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

interface PDFTemplateType {
  created_on: string;
  updated_on: string;
  id: number;
  name: string;
  is_previewable: boolean;
  description: string;
  dealer_id: number | null;
}

export const useGetCommunicationSettingsData = () => {
  const { selectedLocation } = useDealersLocations();
  const { data, isLoading } = useQuery({
    queryFn: async () => {
      return ApiInstance.post("/communication/settings/read", { dealer_location_id: selectedLocation?.id }, ENV.dealerBaseUrl).then(data => data.data.settings);
    },
    queryKey: [queryKeys.dealers.commSettings]
  });

  return { data, isLoading };
};

export function useGetPDFTemplates() {
  const { data, isLoading } = useQuery({
    queryFn: () => ApiInstance.get(`/report_templates/list`).then(res => res.data),
    queryKey: CommunicationSettingsQueryKeys.pdf_templates
  });

  return { data, isLoading } as { data: PDFTemplateType[]; isLoading: boolean };
}

export const useCommunicationSettingsMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: CommunicationSettings) => ApiInstance.post("/communication/settings/update", { settings: formValues }, ENV.dealerBaseUrl),
    onMutate: () => {
      return queryClient.getQueryData([queryKeys.dealers.commSettings]);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.dealers.commSettings] }),
    onError: (err, _, context) => {
      toast.error(err.message);
      queryClient.setQueryData([queryKeys.dealers.commSettings], context);
    }
  });

  return { mutate, isPending };
};
