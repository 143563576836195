import moment from "moment";
import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import "components/DatePicker/DatePicker.scss";
import { ITranslation } from "util/interfaces";

interface BaseDatePickerProps {
  fluid?: boolean;
  disabled?: boolean;
  withPortal?: boolean;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  showLabel?: boolean;
  selectsRange?: any;
  maxDate?: Date;
  date?: Date | null;
  clearable?: boolean;
}

interface SingleDatePickerProps extends BaseDatePickerProps {
  selectsRange?: false;
  onDateChange: (date: Date) => void;
  startDate?: never;
  endDate?: never;
  date: Date | null;
}

interface RangeDatePickerProps extends BaseDatePickerProps {
  selectsRange: any;
  onDateChange: (dates: [Date, Date]) => void;
  startDate?: Date;
  endDate?: Date;
}

type DatePickerProps = SingleDatePickerProps | RangeDatePickerProps;

export enum DATE_FORMATS {
  dateMonthYear = "DD MM YYYY",
  dateMonthYearRange = "DD MM YYYY - DD MM YYYY",
  dateMonthYearHyphen = "DD-MM-YYYY",
  yearMonthDateTime = "YYYY-MM-DDTHH:mm:ssZ",
  yearMonthDateTimeSpace = "YYYY-MM-DD HH:mm:ssZ",
  yearMonthDateTimeNoTimezone = "YYYY-MM-DD HH:mm",
  yearMonthDateSlash = "YYYY/MM/DD"
}

export const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  date,
  fluid,
  disabled,
  withPortal,
  showLabel,
  showMonthDropdown = false,
  showYearDropdown = false,
  selectsRange = false,
  startDate,
  endDate,
  maxDate,
  clearable
}: DatePickerProps) => {
  const t = useTranslation().t as ITranslation;
  const [open, setOpen] = useState<boolean>(false);

  const selectedDate = useMemo(() => {
    if ((selectsRange && startDate && endDate) || date) setOpen(false);

    if (selectsRange && startDate && endDate) {
      const startStr = moment(startDate).format(DATE_FORMATS.dateMonthYear);
      const endStr = moment(endDate).format(DATE_FORMATS.dateMonthYear);
      return `${startStr} - ${endStr}`;
    }

    if (date) {
      if (moment(date).isSame(moment(), "day")) return t("v8_today").message || "Today";
      return moment(date).format(DATE_FORMATS.dateMonthYear);
    }

    if (selectsRange) return t("v8_select_date_range").message || "Select date range";
    return t("v8_select_date").message || "Select date";
  }, [date, startDate, endDate, selectsRange]);

  const handleChange = (dates: Date | [Date | null, Date | null]) => {
    if (!dates) return;

    if (selectsRange) {
      const [start, end] = dates as [Date | null, Date | null];
      (onDateChange as (dates: [Date | null, Date | null]) => void)([start, end]);
      if (start && end) setOpen(false);
    } else {
      (onDateChange as (date: Date) => void)(dates as Date);
      setOpen(false);
    }
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (disabled) return;

    if (selectsRange) (onDateChange as (dates: [Date | null, Date | null]) => void)([null, null]);
    else (onDateChange as (date: Date | null) => void)(null);
  };

  const hasValue = selectsRange ? startDate || endDate : date;

  return (
    <>
      <div className={`datepicker-wrapper ${fluid && "fluid"} ${disabled && "disabled"}`} onClick={() => (disabled ? null : setOpen(true))}>
        {showLabel && (
          <>
            <div className="datepicker-row">
              <p className="label">{t("v8_date").message || "Date"}</p>
              <p className="date">{selectedDate}</p>
            </div>
            <div className="datepicker-icons">
              {clearable && hasValue && (
                <div className="clear-button" onClick={handleClear} title={t("v8_clear").message || "Clear"}>
                  <Icon className="close" />
                </div>
              )}
              <Icon className="dropdown" />
            </div>
          </>
        )}
        <ReactDatePicker
          calendarClassName="CalendarDatepicker"
          open={open}
          onClickOutside={() => setOpen(false)}
          todayButton={<span>{t("v8_today").message || "Today"}</span>}
          calendarStartDay={1}
          showPopperArrow={false}
          placeholderText={selectsRange ? t("v8_select_date_range").message || "Select date range" : t("select_date").message || "Select date"}
          selected={selectsRange ? startDate : date}
          onChangeRaw={event => event?.preventDefault()}
          onChange={handleChange}
          withPortal={!!withPortal}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          selectsRange={selectsRange}
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          dateFormat="dd-MM-yyyy"
        />
      </div>
    </>
  );
};
