const applicationName = "Claire";

export const Logo = () => {
  return (
    <>
      <h1>
        <span>{applicationName.charAt(0)}</span>
        {applicationName.slice(1)}
      </h1>
      <h3>Automotive Support</h3>
    </>
  );
};
