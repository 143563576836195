import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { DayplannerAppointment } from "modules/Dayplanner/interfaces";
import { DayplannerKeys } from "modules/Dayplanner/queryKeys";
import ApiInstance from "util/Api";

type UnassignMutationData = {
  appointment_id: number;
};

export const useUnassignMutation = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: UnassignMutationData) => ApiInstance.post("/dayplanner/appointments/unassign", data, ENV.appointmentBaseURL),
    onMutate: data => {
      if (!selectedLocation?.id) throw new Error("invalid location");
      const queryKey = DayplannerKeys.appointments({ dealer_location_id: selectedLocation.id });

      const appointments = queryClient.getQueryData<DayplannerAppointment[]>(queryKey);
      if (!appointments) return [];

      const appointment = appointments.find(app => app.id === data.appointment_id);
      if (!appointment) return appointments;

      const updatedAppointments = appointments.map(app => {
        if (app.id === appointment.id) return { ...app, assigned_mechanic: null, assigned_mechanic_order: null, last_assigned_mechanic: app.assigned_mechanic };

        if (
          app.assigned_mechanic === appointment.assigned_mechanic &&
          app.assigned_mechanic_order &&
          appointment.assigned_mechanic_order &&
          app.assigned_mechanic_order > appointment.assigned_mechanic_order
        ) {
          return { ...app, assigned_mechanic_order: app.assigned_mechanic_order - 1 };
        }

        return app;
      });

      queryClient.setQueryData<DayplannerAppointment[]>(queryKey, updatedAppointments);

      return appointments;
    },
    onError: (error, _, appointments) => {
      toast.error(error.message);
      if (selectedLocation?.id) queryClient.setQueryData<DayplannerAppointment[]>(DayplannerKeys.appointments({ dealer_location_id: selectedLocation.id }), appointments);
    }
  });

  return mutation.mutate;
};
