import { useQueryClient } from "@tanstack/react-query";

import { DealerDocumentation } from "models";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { DealerDocumentationsQueryKeys } from "modules/DealerDocumentations/queryKeys";
import { ITranslation } from "util/interfaces";

type Params<T> = {
  entityIds: number[] | null;
  entities: T[];
  translationKey: string;
  defaultMessage: string;
  t: ITranslation;
};

export const getEntityNames = <T extends { id: number; name: string }>({ defaultMessage, entities, entityIds, translationKey, t }: Params<T>) => {
  const matchedEntities: string[] = [];

  entityIds?.forEach(id => {
    const resource = entities.find(element => element.id === id);
    if (resource) matchedEntities.push(resource.name);
  });

  return matchedEntities.length > 0 ? matchedEntities.join(", ") : t(translationKey).message || defaultMessage;
};

export const useDealerDocumentationTable = () => {
  const queryClient = useQueryClient();
  const queryKey = DealerDocumentationsQueryKeys.list;
  const { categoryId } = useDealerDocumentationContext();

  const dealersDocumentations: DealerDocumentation[] | undefined = queryClient.getQueryData([queryKey]);

  const filterDealerDocumentationByCategory = () =>
    !categoryId ? dealersDocumentations : dealersDocumentations?.filter(category => category.category_id === categoryId);

  return { dealersDocumentation: filterDealerDocumentationByCategory() };
};
