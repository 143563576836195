import ApiLoadable from "models/ApiLoadable";

export enum EXACT_ACTION_TYPES {
  GetItem = 1,
  GetItemExtraField,
  PostAccount,
  PutAccount,
  PostContact,
  PutContact,
  GetContact,
  PostSalesInvoice,
  GetDocument,
  GetInvoicePdf
}

export enum LICENSE_QUANTITY_CATEGORIES {
  ManufacturerWarrantyManagerReceptionistDealerAdmin = 1,
  SupervisorCallCenter,
  CounterTablet,
  GoogleAccount,
  Mechanic,
  DataHosting,
  ClientcommunicationModule,
  SMS,
  Keylocker,
  TyreScanner,
  TyreTeam,
  Tablet,
  LocationStartupCosts,
  DealerStartupCosts,
  OneiStartupCosts
}

export class OnlineAuth extends ApiLoadable {
  id: number = 0;
  api_key_type: number = 0;
  access_token: string = "";
  refresh_token: string = "";
  expires_at: string = "";
  blocked_until: string | null = null;
}

export class ExactActionType extends ApiLoadable {
  id: number = 0;
  name: string = "";
}

export class ExactOnlineFailure extends ApiLoadable {
  id: number = 0;
  status_code: number = 0;
  request_body: string | null = null;
  url: string = "";
  errormessage: string | null = null;
  exact_action: EXACT_ACTION_TYPES = EXACT_ACTION_TYPES.GetItem;
  dealer_location_id: number = 0;
  dealer_id: number = 0;
  created_on: string = "";
  dealer_name?: string = "";
  location_name?: string = "";
}

export class InvoicingDetails extends ApiLoadable {
  id: number = 0;
  invoice_monthly: boolean = true;
  invoice_on: string | null = null;
}

export class StaticQuantityLicense extends ApiLoadable {
  id: number = 0;
  exact_item_id: string = "";
  quantity: number = 0;
  price: number | null = null;
  invoice_monthly: boolean = true;
  invoice_on: string | null = null;
  dealer_location_id: number = 0;
}

export class LicenseQuantityCategory extends ApiLoadable {
  id: number = 0;
  name: string = "";
}

export class DynamicQuantityLicense extends ApiLoadable {
  id: number = 0;
  tablet_model: string = "";
  exact_item_id: string = "";
  price: number | null = null;
  license_quantity_category_id: LICENSE_QUANTITY_CATEGORIES = LICENSE_QUANTITY_CATEGORIES.ManufacturerWarrantyManagerReceptionistDealerAdmin;
  invoicing_details_id: number = 0;
  dealer_location_id: number = 0;
  quantity?: number = 0;
}
