import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";

import { Can, DatePicker, NavBarSearch, NavBarSearchAppointments, NavPortal } from "components";
import { useSearchTerm } from "components/NavBarSearch/useSearchTerm";
import { useDealersLocations, useRegisterNavAction } from "hooks";
import { STATUS_IDENTIFIER } from "models";
import "modules/Appointments/Appointments.scss";
import { AppointmentTable, NewAppointment, StatusFilters, Timer } from "modules/Appointments/components";
import {
  StatusData,
  useGetAppointmentsSelectedIdentifiers,
  useRealTimeAppointments,
  useSelectedDate,
  useSelectedDateMutation,
  useUpdateAppointmentsSelectedIdentifiers
} from "modules/Appointments/hooks";
import { filterAppointmentsBySearch, filterSpecialIndicators, sortAppointments } from "modules/Appointments/util";
import { getPreference, setPreference } from "util/common";
import { queryKeys } from "util/keyFactory";

export enum WO_FILTERS {
  ALL = "All",
  WO = "WO",
  INTERNAL = "Internal"
}

export const WO_COLUMN_ACCESSOR_KEY = "wo-column-accessor-value";

const Appointments = () => {
  const [woColumnAccessor, setWoColumnAccessor] = useState<WO_FILTERS>(getPreference(WO_COLUMN_ACCESSOR_KEY, WO_FILTERS.ALL));

  const { data: appointments = [], isLoading, isRefetching, isFetching, refetch } = useRealTimeAppointments();

  const updateDate = useSelectedDateMutation();
  const { selectedLocation, isFeatureEnabled } = useDealersLocations();
  const selectedStatusIdentifiersFilters = useGetAppointmentsSelectedIdentifiers();
  const updateSelectedStatusIdentifiersFilters = useUpdateAppointmentsSelectedIdentifiers();

  const queryClient = useQueryClient();
  const searchTerm = useSearchTerm();
  const { data: specialIndicators = {} } = useQuery({ queryKey: [queryKeys.appointments.specialIndicators] });
  const { data: carMakeSearchValue = [] } = useQuery<string[]>({ queryKey: [queryKeys.appointments.carMakeSearch] });

  const date = useSelectedDate();
  const isTodaysList = moment().isSame(date, "day");
  const selectedIdentifiersValues = selectedStatusIdentifiersFilters.filter((item: StatusData) => !item.isActive).map(s => s.value);

  useEffect(() => {
    const carMakeValues = appointments?.map(({ car_make }) => car_make) || [];
    queryClient.setQueryData([queryKeys.appointments.carMakes], [...new Set(carMakeValues)]);
  }, [appointments]);

  const refreshAction = useMemo(
    () => ({
      onClick: refetch
    }),
    [refetch]
  );

  useRegisterNavAction(refreshAction.onClick);

  const handleDateChange = (date: Date) => {
    updateDate(date);
  };

  const filteredAppointments = useMemo(() => {
    if (appointments) {
      let filteredList = appointments?.filter(app => !selectedIdentifiersValues.includes(app.appointment_status_identifier)) || [];
      if (specialIndicators) {
        filteredList = filterSpecialIndicators(filteredList, specialIndicators);
      }
      if (selectedIdentifiersValues.includes(STATUS_IDENTIFIER.CarOutOfShop)) {
        filteredList = filteredList?.filter(app => !app.car_out_of_shop) || [];
      }
      if (searchTerm) {
        filteredList = filterAppointmentsBySearch(filteredList, searchTerm as string);
      }
      if (carMakeSearchValue.length > 0) {
        filteredList = filteredList.filter(({ car_make }) => carMakeSearchValue.includes(car_make));
      }
      if (woColumnAccessor !== WO_FILTERS.ALL) {
        const showInternal = woColumnAccessor === WO_FILTERS.INTERNAL;
        filteredList = filteredList.filter(app => showInternal === app.internal);
      }

      return sortAppointments(filteredList, selectedLocation?.statuses);
    }

    return [];
  }, [appointments, selectedIdentifiersValues, searchTerm, specialIndicators, carMakeSearchValue, selectedLocation?.statuses, woColumnAccessor]);

  const handleChangeWoColumnAccessor = (value: string) => {
    if (Object.values(WO_FILTERS).includes(value as WO_FILTERS)) {
      setWoColumnAccessor(value as WO_FILTERS);
      setPreference(WO_COLUMN_ACCESSOR_KEY, value as WO_FILTERS);
    }
  };

  return (
    <div>
      <NavPortal>
        <NavBarSearch>
          <NavBarSearchAppointments />
        </NavBarSearch>
      </NavPortal>
      <div className="AppointmentActions">
        <div className="AppointmentActions-wrapper">
          {isFeatureEnabled("_LoadTijden") && <Timer />}
          {selectedLocation?.id && (
            <Can I="import" the="appointments">
              <NewAppointment />
            </Can>
          )}
        </div>

        <div className="AppointmentActions-wrapper">
          <DatePicker onDateChange={handleDateChange} date={moment(date).toDate()} showLabel />
          <StatusFilters onChange={() => updateSelectedStatusIdentifiersFilters(selectedStatusIdentifiersFilters)} />
        </div>
      </div>
      <div className="AppointmentsTableContainer">
        <>
          {!isLoading && !isRefetching && !isFetching ? (
            <AppointmentTable
              appointments={filteredAppointments}
              isTodaysList={isTodaysList}
              woColumnAccessor={woColumnAccessor}
              handleChangeWoColumnAccessor={handleChangeWoColumnAccessor}
            />
          ) : (
            <Loader active inline size="small" />
          )}
        </>
      </div>
    </div>
  );
};

export default withTranslation()(Appointments);
