import { toast } from "react-toastify";

import { Appointment, Customer, DealerLocation, ROLES, STATUS_IDENTIFIER, TYRE_SEASONS } from "models";
import { ITranslation } from "util/interfaces";

export const REPORTS_COLORS = ["#278ecf", "#4bd762", "#ff402c", "#ff9416", "#d42ae8", "#535ad7", "#83bfff", "#ffca1f", "#44BA45", "#2F6F24", "#AE2DFF", "#2285D0"];
export const getErrorMessage = (error: any): string => (error.response?.data?.errors[0].length > 0 ? error.response?.data?.errors[0] : "");
export const isNumOrStr = (value: number | string | undefined) => typeof value === "number" || typeof value === "string";
export const defaultFormatter = (value: (number | string)[]) => (Array.isArray(value) && isNumOrStr(value[0]) && isNumOrStr(value[1]) ? value.join(" ~ ") : value);

export const classNames = (classes: Record<string, boolean | undefined | null | 0>): string => {
  return Object.entries(classes)
    .filter(([_, condition]) => Boolean(condition))
    .map(([className]) => className)
    .join(" ");
};

const PREFIX = "preferences-";

export const setPreference = (key: string, value: any) => localStorage.setItem(`${PREFIX}${key}`, JSON.stringify({ value }));

export const getPreference = (key: string, defaultValue: any = null) => {
  let item: any = localStorage.getItem(`${PREFIX}${key}`);

  if (!item) return defaultValue;

  try {
    item = JSON.parse(item);
    return item.value;
  } catch (err) {
    return null;
    throw err;
  }
};

export const removeGenerationParam = (urlPath: string) => {
  if (!urlPath) return "";

  if (urlPath.indexOf("generation") === -1) return urlPath;

  return urlPath.replace("generation", "noversion");
};

export const copyToClipboard = async (txt: string) => {
  try {
    await navigator.clipboard.writeText(txt);
  } catch (err) {
    toast.error("Failed to copy");
    throw err;
  }
};

export const convertCamelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getCustomerName = (customer: string, accessor: any) => {
  if (!accessor) return null;

  switch (customer) {
    case "driver": {
      let driverName = accessor.driver_title || accessor.driver_initials || "";
      driverName += `${accessor.driver_firstname} ${accessor.driver_surname}`.trim() || "";
      if (!driverName) driverName = accessor.company || "";

      return driverName;
    }

    case "contractor": {
      let contractorName = accessor.contractor_title || accessor.contractor_initials || "";
      contractorName += `${accessor.contractor_firstname} ${accessor.contractor_surname}`.trim() || "";
      if (!contractorName) contractorName = accessor.company || "";

      return contractorName;
    }
    case "owner": {
      let ownerName = accessor.owner_title || accessor.owner_initials || "";
      ownerName += `${accessor.owner_firstname} ${accessor.owner_surname}`.trim() || "";
      if (!ownerName) ownerName = accessor.company || "";

      return ownerName;
    }
    case "customer": {
      let customerName = accessor.title || "";
      customerName += `${accessor.firstname} ${accessor.surname}`.trim() || "";
      if (!customerName) customerName = accessor.company || "";

      return customerName;
    }

    default:
      return "";
  }
};

export enum PRINT_SIZES {
  A4 = 1,
  Label
}
export const getBrowserLanguage = () => {
  const locale = localStorage.getItem("locale");
  if (locale && (locale === "en-GB" || locale === "nl-NL" || locale === "de-DE" || locale === "fr-FR")) {
    return locale;
  }
  const queryLanguage = new URLSearchParams(window.location.search).get("lang");
  if (queryLanguage) return queryLanguage;

  let language = "";
  if (navigator.language.includes("en")) {
    language = "en-GB";
  } else if (navigator.language.includes("nl")) {
    language = "nl-NL";
  } else if (navigator.language.includes("de")) {
    language = "de-DE";
  } else if (navigator.language.includes("fr")) {
    language = "fr-FR";
  } else {
    language = "en-GB";
  }
  return language;
};

export const languages = [
  {
    text: "Dutch",
    value: "nl-NL",
    key: "nl"
  },
  {
    text: "French",
    value: "fr-FR",
    key: "fr"
  },
  {
    text: "English",
    value: "en-GB",
    key: "en"
  },
  {
    text: "German",
    value: "de-DE",
    key: "de"
  }
];

export const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";

export const removeDuplicates = <T>(array: T[], key: keyof T): T[] => {
  return array.filter((item, index, self) => index === self.findIndex(obj => obj[key] === item[key]));
};

export function roundTwoDecimals(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export const getPriceInVAT = (price: number, location?: DealerLocation) => {
  const vat = location?.include_vat ? 1 + Number(location.vat) / 100 : 1;
  return roundTwoDecimals(price * vat);
};

export const getPreviewUrl = (url: string): string => {
  let modifiedUrl = url.replace("://www.", "://storage.");
  modifiedUrl = modifiedUrl.replace("/download/storage/v1/b/", "/");
  modifiedUrl = modifiedUrl.replace("/o/", "/");

  return modifiedUrl;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTyreSeasonIcon = (n: TYRE_SEASONS) => {
  switch (n) {
    case TYRE_SEASONS.Winter:
      return "snowflake i-blue";
    case TYRE_SEASONS.Summer:
      return "sun i-yellow";
    case TYRE_SEASONS.AllSeason:
      return "sun dust i-green";
    default:
      return "";
  }
};

export const isAdmin = (role_id: number) => [ROLES.SuperAdmin, ROLES.ClaireAdmin, ROLES.SupportAdmin].includes(role_id);

export const getUserNameInitials = (user: string | Customer | null) => {
  if (!user) return user;

  if (typeof user === "string") {
    const denominator = user.includes("_") ? "_" : " ";

    const values = user.split(denominator).slice(-2);

    return values.map(curr => curr.charAt(0).toUpperCase()).join("");
  }

  return `${user.firstname?.charAt(0).toLocaleUpperCase() || ""} ${user.surname?.charAt(0).toLocaleUpperCase() || ""}`;
};

// eslint-disable-next-line no-var
var timer: NodeJS.Timeout | null = null;

export const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
  return (...args: Parameters<F>) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, delay);
  };
};

export const isCarReadyOrQualityCheckDoneOrCanceled = (appointment: Appointment) => {
  return [
    STATUS_IDENTIFIER.CarReadyStatus,
    STATUS_IDENTIFIER.QualityCheckStatus,
    STATUS_IDENTIFIER.CarOkPlusRepairOverview,
    STATUS_IDENTIFIER.QualityCheckPlusRepairOverview,
    STATUS_IDENTIFIER.CanceledStatus
  ].includes(appointment.appointment_status_identifier);
};

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const getNameFromURL = (url: string) => {
  const name = decodeURIComponent(url);
  return name.indexOf("?") > -1 ? name.slice(name.lastIndexOf("/") + 1, name.indexOf("?")) : name.slice(name.lastIndexOf("/") + 1);
};

export const getExtensionFromURL = (url: string) => {
  const attachmentType = url.match(/\.([^?.]+)(\?.*)?$/);
  return attachmentType?.[1] || "";
};

export const getInitials = (firstName: string, lastName: string) => {
  if (!firstName && !lastName) return "";

  return `${firstName?.toUpperCase().charAt(0) || ""}${lastName?.toUpperCase().charAt(0) || ""}`;
};

export const extractAllNameFields = (data: Record<string, string | number>[]) => {
  const namesCollected: string[] = [];
  data.forEach(item => Object.keys(item).forEach(name => !namesCollected.includes(name) && namesCollected.push(name)));
  return namesCollected;
};

export const translateReportForCharts = (report: string[], t: ITranslation) => {
  const translatedReport: Record<string, unknown> = {};
  const skipKeys = ["name", "value", "total"];
  const prefixes = ["total", "percent", "stack_a", "stack_b"];

  for (const key in report) {
    let translatedKey = key;
    if (!skipKeys.includes(key)) {
      const [prefix, ...restKey] = key.split(".");
      if (restKey?.length && prefixes.includes(prefix)) translatedKey = `${prefix}.${t(restKey.join(".")).message || restKey.join(".")}`;
      else translatedKey = t(key).message || key;
    }
    translatedReport[translatedKey] = report[key];
  }
  return translatedReport;
};
