import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import CarRepair from "assets/img/car-repair.png";
import { Logo } from "components";
import { ReactComponent as LicenseIcon } from "components/NotFoundPage/404-license-plate.svg";
import "components/NotFoundPage/NotFoundPage.scss";
import { useAuth } from "hooks";
import { ITranslation } from "util/interfaces";

export const NotFoundPage = () => {
  const t = useTranslation().t as ITranslation;
  const { redirectToLandingPage } = useAuth();

  return (
    <div className="NotFoundPage">
      <div className="NotFoundPage-overlay">
        <div className="NotFoundPage-container">
          <div className="NotFoundPage-logo">
            <Logo />
          </div>
          <div className="NotFoundPage-action-buttons">
            <Button icon labelPosition="left" onClick={redirectToLandingPage}>
              <Icon className="arrow left" color="white" />
              {t("v8_back_to_homepage").message || "Back to Homepage"}
            </Button>
          </div>
          <div className="NotFoundPage-car-repair">
            <img src={CarRepair} />
            <div className="NotFoundPage-svg-icon">
              <LicenseIcon />
            </div>
            <div className="NotFoundPage-error-text">{t("v8_page_not_found").message || "Page Not Found"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
