import React, { useEffect, useState } from "react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Icon, Input } from "semantic-ui-react";

import Service from "components/UploadImage/service";
import "components/UploadImage/UploadImage.scss";
import { ITranslation } from "util/interfaces";

interface UploadImageProps<T extends FieldValues> {
  name: Path<T>;
  path: string;
  onSuccess?: (url: string) => void;
  onError?: (reason: string) => void;
  fileFieldName?: string;
  formFieldProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const FormUploadImage = <T extends FieldValues>({ name, path, onSuccess, onError, fileFieldName = name, formFieldProps }: UploadImageProps<T>) => {
  const methods = useFormContext<T>();
  const t = useTranslation().t as ITranslation;

  return (
    <Controller
      name={name}
      {...methods}
      render={({ field, fieldState }) => {
        const [file, setFile] = useState<File | null>(null);

        useEffect(() => {
          if (field.value) {
            fetch(field.value)
              .then(res => res.blob())
              .then(blob => setFile(new File([blob], name)));
          }
        }, [field.value]);

        const handleChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
          if (!event.target.files) return;
          const uploadedFile = event.target.files[0];
          const fd = new FormData();
          fd.append(fileFieldName, uploadedFile);
          setFile(uploadedFile);

          try {
            const response = await Service.uploadImage(path, fd);

            if (response?.data?.URL) {
              field.onChange(response.data.URL);
              onSuccess?.(response.data.URL);
            } else {
              throw new Error(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
            }
          } catch (e) {
            let errMessage = "";
            if (e instanceof Error) {
              errMessage = e.message;
            } else {
              errMessage = t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.";
            }
            if (onError) onError(errMessage);
          }
        };

        const handleUpload = () => {
          document.getElementById(name)?.click();
        };

        const handleDelete = () => {
          const fd = new FormData();
          fd.delete(name);
          setFile(null);
          field.onChange(null);
        };

        return (
          <div className="upload-container" {...formFieldProps}>
            <div className="UploadImage new">
              <div className="button" onClick={handleUpload}>
                <p className="btn-text">{t("v8_choose_file").message || "Choose File"}</p>
              </div>
              <p className="fileInput-ellipsis">{file ? t(file?.name)?.message || file?.name : t("v8_choose_file").message || "Choose File"}</p>
            </div>
            <Input id={name} accept="image/png, image/gif, image/jpeg" type="file" style={{ display: "none" }} onChange={handleChangeFile} />
            <div className="upload-view">
              {file ? <img alt="preview image" src={URL.createObjectURL(file)} /> : <Icon className="image i-grey" size="huge" />}
              {file && (
                <div className="delete-container" onClick={handleDelete}>
                  <Icon className="trash i-grey" />
                </div>
              )}
            </div>
            {fieldState.invalid && <div className="error">{t("v8_something_went_wrong").message}</div>}
          </div>
        );
      }}
    />
  );
};
