import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { ReactTable } from "components/ReactTable";
import { isPercentageField } from "modules/DealerReports/util/helper";
import { COLOR_THRESHOLDS } from "modules/DealerReports/util/parser";
import { ParsedReportData } from "modules/DealerReports/util/types";
import { ITranslation } from "util/interfaces";

interface ReportRow {
  [key: string]: any;
}

interface DealerReportTableProps {
  data: ParsedReportData[];
  onExport?: () => void;
  hideExport?: boolean;
}

const formatTableCell = (key: string, value: any): React.ReactNode => {
  if (value === null || value === undefined) return "0";

  if (typeof value === "object" && value !== null && "value" in value) {
    if ("percent" in value) {
      const color = COLOR_THRESHOLDS.geclaired.find(e => value.percent >= e.min && value.percent <= e.max)?.color;
      return (
        <>
          {value.value} (<span style={{ color: value.color || color || "inherit" }}>{value.percent}%</span>)
        </>
      );
    }

    return value.value;
  }

  if (["avg_photo_taken", "avg_sold_items"].includes(key)) {
    const color =
      key === "avg_photo_taken"
        ? COLOR_THRESHOLDS.avgPhotos.find(e => value >= e.min && value <= e.max)?.color
        : COLOR_THRESHOLDS.avgSold.find(e => value >= e.min && value <= e.max)?.color;

    return (
      <>
        <span style={{ color: color || "inherit" }}>{value}</span>
      </>
    );
  }

  if (isPercentageField(key)) return `${value}%`;
  if (typeof value === "number") return value.toString().includes(".") ? Number(value.toFixed(2)) : value;
  return value;
};

const DealerReportTable = ({ data, onExport, hideExport = false }: DealerReportTableProps) => {
  const t = useTranslation().t as ITranslation;

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div className="Table__no-results">
        <p>{t("v8_no_data").message || "No data"}</p>
      </div>
    );
  }

  const columnHelper = createColumnHelper<ReportRow>();
  const columns = Object.keys(data[0])
    .filter(key => key !== "__typename")
    .map(key =>
      columnHelper.accessor(key, {
        header: () => <strong>{t(key).message || key}</strong>,
        cell: info => formatTableCell(key, info.getValue()),
        minSize: 135
      })
    );

  return (
    <div className="dealer-reports-table-container">
      <h3 className="report-heading">
        {!hideExport && onExport && (
          <Button color="green" floated="right" basic onClick={onExport}>
            <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
            <Icon className="export" />
          </Button>
        )}
      </h3>
      <div style={{ maxHeight: "600px", overflow: "auto" }}>
        <ReactTable data={data} columns={columns} />
      </div>
    </div>
  );
};

export default DealerReportTable;
