import { useTranslation } from "react-i18next";

import { DOCUMENTATION_CATEGORIES } from "models";
import { ITranslation } from "util/interfaces";

export type CategoryOptions = { value: DOCUMENTATION_CATEGORIES; text: string };

export const useDealerCategories = () => {
  const t = useTranslation().t as ITranslation;

  const categoryOptions: CategoryOptions[] = Object.values(DOCUMENTATION_CATEGORIES)
    .filter(value => typeof value === "number")
    .map(key => ({
      value: key,
      text: t(`v8_${DOCUMENTATION_CATEGORIES[key].toLowerCase()}`).message || DOCUMENTATION_CATEGORIES[key]
    }));
  const getCategoryName = (id: number | undefined) => categoryOptions.find(category => category.value === id)?.text;

  return { categoryOptions, getCategoryName };
};
