import { useQueryClient } from "@tanstack/react-query";
import _isEqual from "lodash/isEqual";
import { useEffect } from "react";

import { usePrevious } from "hooks";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { DealerDocumentationsQueryKeys } from "modules/DealerDocumentations/queryKeys";

export const useDealerDocumentations = () => {
  const queryKey = DealerDocumentationsQueryKeys.list;
  const queryClient = useQueryClient();
  const {
    visibleToMechanic,
    selectedDealersLocations: { dealerIds, locationIds }
  } = useDealerDocumentationContext();

  const prevDealers = usePrevious(dealerIds);
  const prevDealerLocations = usePrevious(locationIds);
  const prevVisibleToMechanic = usePrevious(visibleToMechanic);

  useEffect(() => {
    if (!_isEqual(prevDealerLocations, locationIds) || !_isEqual(prevDealers, dealerIds) || prevVisibleToMechanic !== visibleToMechanic) {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    }
    if (!locationIds.length && !dealerIds.length) {
      queryClient.resetQueries({ queryKey: [queryKey] });
    }
  }, [visibleToMechanic, locationIds, dealerIds]);
};
