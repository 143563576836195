import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NotificationElement, useUser } from "hooks";
import { Appointment } from "models";
import { NOTIFICATION_ENTITIES } from "modules/Auth/components/AccountSettings/index";
import { NotificationQueryKeys } from "modules/Auth/components/AccountSettings/queryKeys";
import ApiInstance from "util/Api";
import { ITranslation } from "util/interfaces";

const ClaireIcon = `${process.env.PUBLIC_URL}/favicon.ico`;
export const useGetNotiticationList = () => {
  const { data, isLoading } = useQuery({
    queryKey: [NotificationQueryKeys.appointments_list],
    queryFn: () =>
      ApiInstance.get(NotificationQueryKeys.appointments_list.endpoint, NotificationQueryKeys.appointments_list.baseUrl).then(res => res.data.notification_elements)
  });

  return { data, isLoading } as { data: NotificationElement[]; isLoading: boolean };
};

export const useDesktopNotifications = () => {
  const user = useUser();
  const { data: notificationsList } = useGetNotiticationList();
  const t = useTranslation().t as ITranslation;

  const userSubscribedStatusNotifications = useMemo(
    () =>
      notificationsList?.filter(
        notification =>
          notification.notification_entity_id === NOTIFICATION_ENTITIES.StatusIdentifier && user?.notification_element_ids?.includes(notification.notification_element_id)
      ),
    [notificationsList, user]
  );

  const userSubsribedAppointmentNotifications = useMemo(
    () =>
      notificationsList?.filter(
        notification =>
          notification.notification_entity_id === NOTIFICATION_ENTITIES.Appointment && user?.notification_element_ids?.includes(notification.notification_element_id)
      ),
    [notificationsList, user]
  );

  const processDesktopNotifications = (previousAppState: Appointment, updatedAppState: Appointment, userId: number | undefined) => {
    if (user?.notification_element_ids?.length === 0 || Notification.permission !== "granted" || !userId || user?.id === userId) return;
    if (previousAppState.appointment_status_identifier !== updatedAppState.appointment_status_identifier) {
      const matchingNotification = userSubscribedStatusNotifications.find(
        notification => updatedAppState.appointment_status_identifier === Number(notification.field.value)
      );
      if (matchingNotification)
        new Notification(`${updatedAppState.wo_nr} - ${t("v8_new_status_update").message || "New Status Update"}`, {
          body: `${t("v8_status_updated_to").message || "Status Updated to"} ${t(matchingNotification.field.name).message || matchingNotification.field.name}`,
          icon: ClaireIcon
        });
    }

    userSubsribedAppointmentNotifications.forEach(notification => {
      const iterrationField = notification.field.value as keyof Appointment;
      if (updatedAppState[iterrationField] && previousAppState[iterrationField] !== updatedAppState[iterrationField])
        new Notification(`${updatedAppState.wo_nr} - ${t("v8_new_appointment_update").message || "New Appointment Update"}`, {
          body: `${t("v8_appointment_updated_to").message || "Appointment updated to"} ${t(notification.field.name).message || notification.field.name}`,
          icon: ClaireIcon
        });
    });
  };

  const processNewAppointmentNotification = (appointment: Appointment) => {
    if (user?.notification_element_ids?.length === 0 || Notification.permission !== "granted") return;
    const matchingNotification = userSubscribedStatusNotifications.find(notification => appointment.appointment_status_identifier === Number(notification.field.value));
    if (matchingNotification)
      new Notification(`${t("v8_new_appointment").message || "New Appointment!"}`, {
        body: t(matchingNotification.field.name).message || matchingNotification.field.name,
        icon: ClaireIcon
      });
  };
  return { processDesktopNotifications, processNewAppointmentNotification };
};
