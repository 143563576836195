import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Form } from "semantic-ui-react";

import { FormCheckbox, FormTextEditor } from "components";
import { CommunicationSettings } from "models";
import "modules/ComunicationSettings/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface SupportProps {
  tabId: string;
}

export const Support = ({ tabId }: SupportProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_support").message || "SUPPORT"}</h3>
      <div>
        <div className="row full mt-24">
          <Form.Field>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="support_enabled" />
              </span>
              {t("v8_enable_the_support_page").message || "Enable the Support page"}
            </p>
          </Form.Field>
        </div>

        <div className="row full mt-24">
          <FormTextEditor<CommunicationSettings> name="support_text" />
        </div>
      </div>
    </Element>
  );
};
