import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CarDetailsKeys } from "modules/CarDetails/queryKeys";
import apiInstance from "util/Api";

export const useDeleteTyres = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async (id: number) => {
      await apiInstance.post(`/tyres/remove_storage_set`, { car_id: id });
    },

    onSuccess: (_, variable) => {
      const carDetailsQueryKey = CarDetailsKeys.details(variable);
      queryClient.invalidateQueries({ queryKey: carDetailsQueryKey });
    }
  });

  return { mutate, isPending };
};
