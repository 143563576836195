import { DealerDocumentationsProvider } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import DealerDocumentationsPage from "modules/DealerDocumentations/DealerDocumentationsPage";

const DealerDocumentations = () => (
  <DealerDocumentationsProvider>
    <DealerDocumentationsPage />
  </DealerDocumentationsProvider>
);

export default DealerDocumentations;
