import moment from "moment";
import { Label } from "semantic-ui-react";

import { PinUserLabel } from "components";
import { PinModel } from "models";
import "modules/AppointmentDetails/components/Interventions/components/ImportantNotes/ImportantNotes.scss";

type Props = {
  data: PinModel[] | undefined;
};

export const ImportantNotes = ({ data }: Props) => {
  if (!data) return null;

  const onlyImportantOnes = data.filter(element => element.visible_important_items);

  return (
    <div>
      {onlyImportantOnes.map(currentPin => {
        return (
          <div key={currentPin.id} className="important-notes-container">
            <PinUserLabel currentPin={currentPin} />
            {currentPin.updated_on && <Label basic>{moment(currentPin.updated_on).format("DD-MM-YYYY HH:mm")}</Label>}
            <span>{currentPin.note}</span>
          </div>
        );
      })}
    </div>
  );
};
