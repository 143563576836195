import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import AceEditor from "react-ace";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { FormField, FormFieldProps } from "semantic-ui-react";

import "components/Form/FormTextEditor/FormTextEditorStyle.scss";
import "components/TextEditor/TextEditor.scss";

type FormTextEditorProps<T extends FieldValues> = {
  name: Path<T>;
  editorProps?: Partial<React.ComponentProps<typeof AceEditor>>;
  formFieldProps?: FormFieldProps;
};

export const FormTextEditor = <T extends FieldValues>({ name, editorProps, formFieldProps }: FormTextEditorProps<T>) => {
  const methods = useFormContext<T>();

  return (
    <Controller
      name={name}
      {...methods}
      render={({ field, fieldState: { invalid } }) => (
        <FormField {...formFieldProps}>
          <div className={`aceEditorContainer ${invalid ? "aceEditorContainerInvalid" : ""}`}>
            <AceEditor
              placeholder={editorProps?.placeholder || "Enter Message"}
              {...field}
              mode="java"
              theme="github"
              name={name}
              editorProps={{ $blockScrolling: true }}
              className="aceEditor"
              {...editorProps}
            />
          </div>
        </FormField>
      )}
    />
  );
};
