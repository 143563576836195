import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import CarRepair from "assets/img/car-repair.png";
import { Logo } from "components";
import { ReactComponent as LicenseIcon } from "components/ErrorFallback/500-license-plate.svg";
import "components/ErrorFallback/ErrorFallback.scss";
import { useAuth } from "hooks";
import { ITranslation } from "util/interfaces";

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorFallback: FC<Props> = ({ resetErrorBoundary }) => {
  const t = useTranslation().t as ITranslation;
  const { redirectToLandingPage } = useAuth();

  return (
    <div className="ErrorFallback">
      <div className="ErrorFallback-overlay">
        <div className="ErrorFallback-container">
          <div className="ErrorFallback-logo">
            <Logo />
          </div>
          <div className="ErrorFallback-action-buttons">
            <Button icon labelPosition="left" onClick={redirectToLandingPage}>
              <Icon className="arrow left" color="white" />
              {t("v8_back_to_homepage").message || "Back to Homepage"}
            </Button>
            <Button color="light" onClick={resetErrorBoundary}>
              {t("v8_reload_page").message || "Reload page"}
            </Button>
          </div>
          <div className="NotFoundPage-car-repair">
            <img src={CarRepair} />
            <div className="NotFoundPage-svg-icon">
              <LicenseIcon />
            </div>
            <div className="NotFoundPage-error-text">{t("v8_internal_server_error").message || "Internal Server Error"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
