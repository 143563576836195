import { FunctionComponentElement, SyntheticEvent, createElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownDivider, DropdownHeader, DropdownMenu, DropdownMenuProps, DropdownProps } from "semantic-ui-react";

import { DealersLocationsDropdownValue } from "components";
import { useDealersLocations, useUser } from "hooks";
import { isAdmin } from "util/common";
import { ITranslation } from "util/interfaces";

type Props = {
  handleOnChange: ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => void;
  allowSelectDealers?: boolean;
  allowSelectAllDealers?: boolean;
};

const MAX_DEALER_NAME_CHARS = 3;

interface DropdownOption {
  key: string;
  disabled: boolean;
  icon: string;
  text: string;
  labelText: string;
  value: string;
  children?: FunctionComponentElement<DropdownMenuProps>;
}

export const useDealersLocationsMultipleSelectionDropdown = ({ allowSelectDealers, allowSelectAllDealers, handleOnChange }: Props) => {
  const t = useTranslation().t as ITranslation;
  const { dealers, locations } = useDealersLocations();
  const user = useUser();
  const [allDealersSelected, setAllDealersSelected] = useState(false);

  const createDealerOptionMenu = (dealerName: string) =>
    createElement(DropdownMenu, { scrolling: true }, createElement(DropdownHeader, { content: dealerName }), createElement(DropdownDivider, null));

  const groupedOptions = useMemo(() => {
    const options: DropdownOption[] = [];
    const activeDealers = dealers?.filter(element => element.active);
    if (!activeDealers || activeDealers.length === 0) return options;

    activeDealers
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach(dealer => {
        const { name: dealerName, id: dealerId } = dealer;

        options.push({
          icon: "house",
          disabled: !!allowSelectDealers,
          key: `dealer-menu-${dealerId}`,
          labelText: dealerName,
          text: dealerName,
          value: `dealer-menu-${dealerId}`,
          children: createDealerOptionMenu(dealerName)
        });

        const activeLocations = dealer.locations?.filter(location => location.active);
        if (!activeLocations || activeLocations.length === 0) return;

        if (allowSelectDealers)
          options.push({
            icon: "house",
            disabled: false,
            key: `dealer-${dealerId}`,
            value: `dealer-${dealerId}`,
            labelText: dealerName,
            text: dealerName
          });

        activeLocations
          .sort((a, b) => a.name.localeCompare(b.name))
          .forEach(loc => {
            options.push({
              icon: "location dot",
              text: loc.name,
              labelText: `${dealerName.substring(0, MAX_DEALER_NAME_CHARS)} - ${loc.name}`,
              key: `location-${loc.id}`,
              value: `location-${loc.id}`,
              disabled: false
            });
          });
      });

    if (options.length > 0 && allowSelectAllDealers && user && isAdmin(user.role_id)) {
      options.unshift({
        disabled: false,
        icon: "house",
        text: t("v8_all_dealers").message || "All dealers",
        labelText: t("v8_all_dealers").message || "All dealers",
        key: "all",
        value: "all"
      });
    }

    return options;
  }, [user?.role_id, dealers, locations, allowSelectDealers, allowSelectAllDealers]);

  const handleOnSelectionChange = (_: SyntheticEvent, data: DropdownProps) => {
    let dealerIds: number[] = [];
    let locationIds: [dealer_id: number, location_id: number][] = [];

    const options = data.value as string[];
    const isAllDealersSelected = options.includes("all") && !allDealersSelected;

    if (!isAllDealersSelected) {
      options.forEach(option => {
        if (option.startsWith("all")) return;

        if (option.startsWith("dealer-")) {
          const dealer_id = Number(option.substring(7));
          dealerIds.push(dealer_id);
          locationIds = locationIds.filter(loc => loc[0] !== dealer_id);
        }

        if (option.startsWith("location-")) {
          const location_id = Number(option.substring(9));
          const dealer_id = locations.find(loc => loc.id === location_id)?.dealer_id as number;
          locationIds.push([dealer_id, location_id]);
          dealerIds = dealerIds.filter(id => id !== dealer_id);
        }
      });
    }

    setAllDealersSelected(isAllDealersSelected);
    handleOnChange({ dealerIds: isAllDealersSelected ? dealers.map(d => d.id) : dealerIds, locationIds: locationIds.map(element => element[1]) });
  };

  return { groupedLocationsOptions: groupedOptions, handleOnSelectionChange, allDealersSelected };
};
