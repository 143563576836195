import { useTranslation } from "react-i18next";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm } from "components";
import { DealerDocumentation } from "models";
import "modules/DealerDocumentations/components/AddEditDealerDocumentationModal/AddEditDealerDocumentationModal.scss";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { useDeleteDocumentationMtn } from "modules/DealerDocumentations/service";
import { ITranslation } from "util/interfaces";

interface PropTypes {
  documentation: DealerDocumentation;
  showDeleteConfirmation: boolean;
  setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddEditDealerDocumentationModalCustomConfirm = ({ documentation, setShowDeleteConfirmation, showDeleteConfirmation }: PropTypes) => {
  const t = useTranslation().t as ITranslation;
  const { handleSetShowAddEditDealerDocumentationModal } = useDealerDocumentationContext();
  const { mutate: deleteMutation, isPending: isDeleting } = useDeleteDocumentationMtn();

  const handleCancel = () => setShowDeleteConfirmation(false);
  const handleConfirm = () => deleteMutation(documentation.id, { onSuccess: () => handleSetShowAddEditDealerDocumentationModal(null) });

  return (
    <CustomConfirm
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
      isLoading={isDeleting}
      isOpen={showDeleteConfirmation}
      type={CUSTOM_CONFIRM_TYPES.Danger}
      confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
    />
  );
};
