import { Icon } from "semantic-ui-react";

import "components/Notes/NoteTypeIcon/NoteTypeIcon.scss";
import { APPOINTMENT_NOTE_TYPES } from "models";

type NoteTypeIconProps = {
  type: number | null | undefined;
};

export const NoteTypeIcon = ({ type }: NoteTypeIconProps) => {
  switch (type) {
    case APPOINTMENT_NOTE_TYPES.Info:
      return <Icon className="info appointment-note-info-type-icon" style={{ color: "#2185D0" }} />;
    case APPOINTMENT_NOTE_TYPES.BackOrder:
      return <span style={{ color: "#6C0699" }}>BO</span>;
    case APPOINTMENT_NOTE_TYPES.Wo:
      return <Icon className="file alt appointment-note-type-icon" style={{ color: "#2185D0" }} />;
    case APPOINTMENT_NOTE_TYPES.Main:
      return <Icon className="sticky note appointment-note-type-icon" style={{ color: "#FF0202" }} />;
    case APPOINTMENT_NOTE_TYPES.CallCustomer:
      return <Icon className="phone alt appointment-note-type-icon" style={{ color: "#21BA45" }} />;
    case APPOINTMENT_NOTE_TYPES.BillNote:
      return <Icon className="file invoice dollar appointment-note-type-icon" style={{ color: "#46B046" }} />;
    case APPOINTMENT_NOTE_TYPES.TireScanner:
      return <Icon className="tile" style={{ color: "#FF8800" }} />;
    case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
      return <Icon className="steering wheel appointment-note-type-icon" style={{ color: "#5C5C5C" }} />;
    case APPOINTMENT_NOTE_TYPES.RecurringCar:
      return <Icon className="exclamation triangle appointment-note-type-icon" style={{ color: "#C83628" }} />;
    case APPOINTMENT_NOTE_TYPES.Attachment:
      return <Icon className="paperclip appointment-note-type-icon" style={{ color: "#21A1D0" }} />;
    case APPOINTMENT_NOTE_TYPES.Parking:
      return <Icon className="square parking appointment-note-type-icon" style={{ color: "#21A1D0" }} />;
    default:
      return null;
  }
};
