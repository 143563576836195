import { User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum CAR_NOTE_TYPE {
  Default = 1,
  DMS
}

export class CarNoteAttachment extends ApiLoadable {
  id: number = 0;
  url: string = "";
  created_on?: string = "";
  updated_on?: string = "";
  type?: string = "";
  name?: string = "";
  car_note_id?: number = 0;
  user?: User | null = null;
  user_id?: number = 0;
}

export class CarNote extends ApiLoadable {
  id: number = 0;
  note: string = "";
  car_id: number = 0;
  dms_nr?: string = "";
  created_on?: string = "";
  updated_on?: string = "";
  updated_by?: User | null = null;
  created_by?: User | null = null;
  created_by_id?: number = 0;
  updated_by_id?: number = 0;
  visible_to_mechanic: boolean = false;
  visible_on_wo: boolean = false;
  car_note_type_id: number = 0;
  attachments?: CarNoteAttachment[] | null = null;
}
