import { AddDealerDocumentationsButton, DealerDocumentationsFilters } from "modules/DealerDocumentations/components/DealerDocumentationToolbar/components";
import { CategoryOptions } from "modules/DealerDocumentations/hooks";

type Props = { categoryOptions: CategoryOptions[] };

export const DealerDocumentationsToolbar = ({ categoryOptions }: Props) => (
  <div className="dealer-toolbar-container">
    <DealerDocumentationsFilters categoryOptions={categoryOptions} />
    <AddDealerDocumentationsButton />
  </div>
);
