import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { DayplannerAppointment } from "modules/Dayplanner/interfaces";
import { DayplannerKeys } from "modules/Dayplanner/queryKeys";
import ApiInstance from "util/Api";

type AssignMutationData = {
  appointment_id: number;
  user_id: number;
  order: number;
};

export const useAssignMutation = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: AssignMutationData) => ApiInstance.post("/dayplanner/appointments/assign", data, ENV.appointmentBaseURL),
    onMutate: async data => {
      if (!selectedLocation?.id) throw new Error("invalid location");
      const queryKey = DayplannerKeys.appointments({ dealer_location_id: selectedLocation.id });
      const appointments = queryClient.getQueryData<DayplannerAppointment[]>(queryKey);

      const updatedAppointments = appointments?.map(app => {
        if (app.id === data.appointment_id) return { ...app, assigned_mechanic: data.user_id, assigned_mechanic_order: data.order };

        if (app.assigned_mechanic === data.user_id && Number(app.assigned_mechanic_order) >= data.order)
          return { ...app, assigned_mechanic_order: Number(app.assigned_mechanic_order) + 1 };

        return app;
      });

      queryClient.setQueryData<DayplannerAppointment[]>(queryKey, updatedAppointments ?? []);

      return appointments;
    },
    onError: (error, _, appointments) => {
      toast.error(error.message);
      if (selectedLocation?.id) queryClient.setQueryData<DayplannerAppointment[]>(DayplannerKeys.appointments({ dealer_location_id: selectedLocation.id }), appointments);
    }
  });

  return mutation.mutate;
};
