import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "react-toastify";

import { AppointmentsKeys } from "modules/Appointments/queryKeys";
import { dateFormat } from "util/common";

interface DateMutationContext {
  previousDate?: string;
}

export const useSelectedDate = () => {
  return useQueryClient().getQueryData<string>(AppointmentsKeys.selectedDate) || moment().startOf("day").format(dateFormat);
};

export const useSelectedDateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Date, Error, Date, DateMutationContext>({
    mutationFn: async newDate => Promise.resolve(newDate),
    onMutate: newDate => {
      const previousDate = queryClient.getQueryData<string>(AppointmentsKeys.selectedDate) || moment().startOf("day").format(dateFormat);
      queryClient.setQueryData(AppointmentsKeys.selectedDate, moment(newDate).startOf("day").format(dateFormat));

      return { previousDate };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(AppointmentsKeys.selectedDate, context?.previousDate);
      toast.error(error.message);
    }
  });

  return mutation.mutate;
};
