import { Controller, FieldValues, Path } from "react-hook-form";
import { Dropdown, DropdownProps, FormField, FormFieldProps } from "semantic-ui-react";

type TypeFormDropdown<T extends FieldValues> = {
  name: Path<T>;
  dropdownProps?: DropdownProps;
  formFieldProps?: FormFieldProps;
};

export const FormDropdown = <T extends FieldValues>({ name, dropdownProps, formFieldProps }: TypeFormDropdown<T>) => {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { invalid, isTouched } }) => (
        <FormField {...formFieldProps} error={invalid && isTouched}>
          <Dropdown
            {...dropdownProps}
            value={field.value}
            onChange={(_, data) => {
              field.onChange(data.value);
            }}
            onBlur={field.onBlur}
            selection
          />
        </FormField>
      )}
    />
  );
};
