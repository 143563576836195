import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { Button, Checkbox, CheckboxProps, Dropdown, Form, Grid, GridColumn, GridRow, Icon, Input, Modal } from "semantic-ui-react";

import { Can, DealersLocationsMultipleSelectionDropdown, UploadFilesZone } from "components";
import { useUser } from "hooks";
import { DOCUMENTATION_CATEGORIES, DealerDocumentation } from "models";
import "modules/DealerDocumentations/components/AddEditDealerDocumentationModal/AddEditDealerDocumentationModal.scss";
import { AddEditDealerDocumentationModalCustomConfirm, ImagePreview } from "modules/DealerDocumentations/components/AddEditDealerDocumentationModal/components";
import { useAddEditDealerDocumentationModal } from "modules/DealerDocumentations/components/AddEditDealerDocumentationModal/useAddEditDealerDocumentationModal";
import { CategoryOptions } from "modules/DealerDocumentations/hooks";
import { ITranslation } from "util/interfaces";

// eslint-disable-next-line import/order
import "react-quill/dist/quill.snow.css";

type Props = {
  documentation: DealerDocumentation;
  categoryOptions: CategoryOptions[];
};

export const AddEditDealerDocumentationModal = ({ categoryOptions, documentation }: Props) => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const modalTitle = documentation.id ? t("v8_edit_documentation").message || "Edit Documentation" : t("v8_add_documentation").message || "Add documentation";
  const {
    url,
    title,
    content,
    isPinned,
    categoryId,
    publishDate,
    visibleToMechanic,
    showDeleteConfirmation,
    selectedDealersLocations,
    setUrl,
    closeModal,
    setContent,
    handleSave,
    setIsPinned,
    onFileChange,
    setCategoryId,
    onFileDragOver,
    setPublishDate,
    setVisibleToMechanic,
    handleTitleInputChange,
    setShowDeleteConfirmation,
    setSelectedDealersLocations
  } = useAddEditDealerDocumentationModal(documentation);

  const isAuthorizedToDelete = () => {
    if (!user || !documentation || documentation.id < 0) return false;

    if ((documentation.created_by && user.role_id < documentation.created_by?.role_id) || user.id === documentation.created_by?.id) return true;
  };

  return (
    <>
      <Modal className="AddEditDealerDocumentationModal" open={!!documentation} size="large">
        <Modal.Header>
          <h3 className="documentation-modal-title">{modalTitle}</h3>
        </Modal.Header>
        <Modal.Content>
          <Form error>
            <Grid>
              <GridRow>
                <GridColumn>
                  <Form.Field>
                    <Input name="title" required onChange={handleTitleInputChange} defaultValue={title} placeholder={t("v8_title").message || "Title"} />
                  </Form.Field>
                </GridColumn>
              </GridRow>

              <GridRow>
                <GridColumn width={3}>
                  <Form.Field required>
                    <Dropdown
                      fluid
                      selection
                      value={categoryId}
                      options={categoryOptions}
                      noResultsMessage={t("v8_no_categories").message || "No categories"}
                      placeholder={t("v8_select_category").message || "Select a category"}
                      onChange={(_, { value }) => setCategoryId(value as DOCUMENTATION_CATEGORIES)}
                    />
                  </Form.Field>
                </GridColumn>
                <GridColumn width={6}>
                  <Form.Field required>
                    <DealersLocationsMultipleSelectionDropdown values={selectedDealersLocations} handleOnChange={setSelectedDealersLocations} />
                  </Form.Field>
                </GridColumn>
                <GridColumn width={3}>
                  <Form.Field>
                    <DatePicker showYearDropdown showMonthDropdown dateFormat="dd-MM-yyyy" onChange={e => setPublishDate(e)} selected={publishDate} />
                  </Form.Field>
                </GridColumn>
                <GridColumn width={1}>
                  <Form.Field>
                    <Button basic icon onClick={() => setIsPinned(curr => !curr)} className="pin-container">
                      <Icon className={isPinned ? "pin" : "circle regular"} />
                      <span> {t("v8_pin").message || "Pin"} </span>
                    </Button>
                  </Form.Field>
                </GridColumn>
                <GridColumn width={3}>
                  <Form.Field>
                    <div className="flex">
                      <div className="flex-align-center">
                        <Checkbox
                          toggle
                          checked={visibleToMechanic}
                          className="checkbox-is-visible"
                          onChange={(_, { checked }: CheckboxProps) => setVisibleToMechanic(checked || false)}
                        />
                      </div>
                      <span> {t("v8_visible_for_mechanic").message || "Visible for mechanic"} </span>
                    </div>
                  </Form.Field>
                </GridColumn>
              </GridRow>

              <GridRow>
                <GridColumn>
                  <Form.Field>
                    <ReactQuill modules={{ toolbar: ["bold", "italic", "underline", "link", "image", "video"] }} value={content} onChange={setContent} />
                  </Form.Field>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <Form.Field>
                    <UploadFilesZone onFileInputChange={onFileChange} onFileDragOver={onFileDragOver} onFileDrop={onFileChange} display={!url} hasError={false} />
                    {url && (
                      <ImagePreview url={url}>
                        <Icon className="trash" onClick={() => setUrl(undefined)} />
                      </ImagePreview>
                    )}
                  </Form.Field>
                </GridColumn>
              </GridRow>

              <GridRow>
                <GridColumn width={8}>
                  {isAuthorizedToDelete() && (
                    <Can I="delete" the="dealer-documentation">
                      <Button color="red" onClick={() => setShowDeleteConfirmation(true)}>
                        {t("v8_delete").message || "Delete"}
                      </Button>
                    </Can>
                  )}
                </GridColumn>
                <GridColumn width={8}>
                  <div className="cancel-save-btn-wrapper">
                    <Button color="light" onClick={closeModal}>
                      {t("v8_cancel").message || "Cancel"} <Icon className="xmark outline" />
                    </Button>
                    <Can I={["create", "update"]} the="dealer-documentation">
                      <Button
                        color="green"
                        onClick={handleSave}
                        disabled={!title || (!selectedDealersLocations.dealerIds.length && !selectedDealersLocations.locationIds.length)}
                      >
                        {t("v8_save").message || "Save"} <Icon className="check" />
                      </Button>
                    </Can>
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>

      <AddEditDealerDocumentationModalCustomConfirm
        documentation={documentation}
        showDeleteConfirmation={showDeleteConfirmation}
        setShowDeleteConfirmation={setShowDeleteConfirmation}
      />
    </>
  );
};
