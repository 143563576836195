import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";

import { ReportColor, ReportThreshold } from "models";
import "modules/LocationReports/components/SimpleBarChart/SimpleBarChart.scss";
import { REPORTS_COLORS, defaultFormatter, extractAllNameFields, isNumOrStr } from "util/common";
import { ITranslation } from "util/interfaces";

type SimpleBarChartProps = {
  data: Record<string, string | number>[];
  colors?: ReportColor[];
  unit?: string;
  thresholds?: ReportThreshold[];
};

type BarLabelProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
  index: number;
  dataKey: string;
};

const VerticalOffset = -6;

const SimpleBarChart = ({ data, colors, unit, thresholds }: SimpleBarChartProps) => {
  const t = useTranslation().t as ITranslation;

  let iBar = 0;

  const renderCustomTooltip = ({ active, payload, label, formatter, separator = ":" }: TooltipProps<number, string>) => {
    if (!active) return null;
    return (
      <div
        className="recharts-default-tooltip"
        style={{ margin: 0, padding: 10, backgroundColor: "white", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap" }}
      >
        <p className="recharts-tooltip-label" style={{ margin: 0 }}>
          {label}
        </p>
        <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
          {payload?.map((entry: any, i: number) => {
            if (entry.type === "none") return null;

            const itemStyle = {
              display: "block",
              paddingTop: 4,
              paddingBottom: 4,
              color: entry.color || "#000"
            };

            const formatData = entry.formatter || formatter || defaultFormatter;
            let { name, value, unit } = entry;
            if (formatData) {
              const formatted = formatData(value, name, entry, i, payload);
              if (Array.isArray(formatted)) [value, name] = formatted;
              else value = formatted;
            }

            let totalTooltip = null;
            let percentTooltip = null;

            if (entry.payload) {
              let updatedkey: string | null = null;
              let key = Object.keys(entry.payload).find(k => k.startsWith("total." + name + "."));
              if (key) updatedkey = key.replace("total." + name + ".", "");
              else if (i === payload.length - 1) {
                key = Object.keys(entry.payload).find(k => k.startsWith("total._last_."));
                if (key) updatedkey = key.replace("total._last_.", "");
              }

              if (updatedkey && key) {
                updatedkey = t(updatedkey).message || updatedkey;
                totalTooltip = updatedkey + ": " + entry.payload[key];
              }

              updatedkey = null;
              key = Object.keys(entry.payload).find(k => k === "percent." + name);
              if (key) updatedkey = key.replace("percent.", "");

              if (updatedkey && key) {
                percentTooltip = updatedkey + ": " + entry.payload[key] + "%";
              }
            }

            if (unit === " min") {
              value = moment.duration(value, "seconds").format("HH:mm:ss", { trim: false });
              unit = "";
            }

            return (
              <React.Fragment key={`tooltip-item-${i}`}>
                <li className="recharts-tooltip-item" style={itemStyle}>
                  {isNumOrStr(name) ? (
                    <>
                      <span className="recharts-tooltip-item-name">{name}</span>
                      <span className="recharts-tooltip-item-separator">{separator}</span>
                    </>
                  ) : null}
                  <span className="recharts-tooltip-item-value">{value}</span>
                  <span className="recharts-tooltip-item-unit">{unit || ""}</span>
                </li>
                {totalTooltip && (
                  <li className="recharts-tooltip-item" style={itemStyle}>
                    <span className="recharts-tooltip-item-value">{totalTooltip}</span>
                  </li>
                )}
                {percentTooltip && (
                  <li className="recharts-tooltip-item" style={itemStyle}>
                    <span className="recharts-tooltip-item-value">{percentTooltip}</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderCustomBarLabel = ({ x, y, width, index }: BarLabelProps) => {
    let label = null;
    const dataKey = data[index]?.dataKey;
    if (data[index]) {
      let key = Object.keys(data[index]).find(k => k.startsWith("total." + dataKey + "."));
      if (!key) key = Object.keys(data[index]).find(k => k.startsWith("total._last_."));

      if (key) label = data[index][key];
    }

    if (!label) return <span></span>;

    return (
      <text x={x + width / 2} y={y} fill="#000000" textAnchor="middle" dy={VerticalOffset}>
        {label || ""}
      </text>
    );
  };

  const renderCustomThresholdsLegend = () => {
    if (!thresholds?.length) return null;

    return (
      <ul className="recharts-default-legend" style={{ padding: 0, margin: 0, textAlign: "center" }}>
        {thresholds.map((th: ReportThreshold, index: number) => (
          <li key={index} className="recharts-legend-item" style={{ display: "inline-block", marginRight: "10px" }}>
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
            >
              <line strokeWidth="4" fill="none" stroke={th.color} x1="0" y1="16" x2="32" y2="16" className="recharts-legend-icon"></line>
            </svg>
            <span className="recharts-legend-item-text">{t(th.name).message || th.name}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="SimpleBarChart">
      <ResponsiveContainer width="100%" height={450} className="margin-auto">
        <BarChart data={data} margin={{ top: 20, right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {unit === " min" && <YAxis width={80} tickFormatter={value => moment.duration(value, "seconds").format("HH:mm:ss", { trim: false })} />}
          {unit !== " min" && <YAxis unit={unit === "%" ? "%" : undefined} domain={unit === "%" ? [0, 100] : ["auto", "auto"]} />}
          <Tooltip content={renderCustomTooltip} />
          <Legend />

          {extractAllNameFields(data)
            .filter((k: string) => k !== "name" && !k.startsWith("total.") && !k.startsWith("percent."))
            .map((k: string, i: number) => {
              const item = colors && colors.find(col => (t(col.name).message || col.name) === k);
              const color = item?.color || null;

              return (
                <Bar
                  key={"bar" + i}
                  maxBarSize={100}
                  dataKey={k}
                  fill={color || REPORTS_COLORS[iBar++ % REPORTS_COLORS.length]}
                  unit={unit && (t(unit).message || unit)}
                  label={params => renderCustomBarLabel({ dataKey: k, ...params })}
                  isAnimationActive={false}
                />
              );
            })}

          {thresholds && thresholds.map((t, i) => <ReferenceLine key={"threshold" + i} y={t.y} isFront={true} stroke={t.color} />)}
        </BarChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height={30} className="margin-auto">
        <BarChart margin={{ top: 10, right: 30, bottom: 5 }}>
          <Legend content={renderCustomThresholdsLegend} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SimpleBarChart;
